const languages = [
  { label: 'Afrikaans', value: 'afrikaans' },
  { label: 'Albanian', value: 'albanian' },
  { label: 'Amharic', value: 'amharic' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Armenian', value: 'armenian' },
  { label: 'Aymara', value: 'aymara' },
  { label: 'Azerbaijani', value: 'azerbaijani' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Berber', value: 'berber' },
  { label: 'Bosnian', value: 'bosnian' },
  { label: 'Bulgarian', value: 'bulgarian' },
  { label: 'Catalan', value: 'catalan' },
  { label: 'Chinese (Mandarin)', value: 'chinese (mandarin)' },
  { label: 'Croatian', value: 'croatian' },
  { label: 'Czech', value: 'czech' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dari', value: 'dari' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'English', value: 'english' },
  { label: 'Estonian', value: 'estonian' },
  { label: 'Fiji Hindi', value: 'fiji hindi' },
  { label: 'Filipino', value: 'filipino' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'French', value: 'french' },
  { label: 'Galician', value: 'galician' },
  { label: 'Georgian', value: 'georgian' },
  { label: 'German', value: 'german' },
  { label: 'Greek', value: 'greek' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Hebrew', value: 'hebrew' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Hiri Motu', value: 'hiri motu' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Icelandic', value: 'icelandic' },
  { label: 'Indonesian', value: 'indonesian' },
  { label: 'Italian', value: 'italian' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Kannada', value: 'kannada' },
  { label: 'Kazakh', value: 'kazakh' },
  { label: 'Korean', value: 'korean' },
  { label: 'Latvian', value: 'latvian' },
  { label: 'Lithuanian', value: 'lithuanian' },
  { label: 'Macedonian', value: 'macedonian' },
  { label: 'Maori', value: 'maori' },
  { label: 'Mongolian', value: 'mongolian' },
  { label: 'Norwegian', value: 'norwegian' },
  { label: 'Persian (Farsi)', value: 'persian (farsi)' },
  { label: 'Polish', value: 'polish' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Quechua', value: 'quechua' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'Romansh', value: 'romansh' },
  { label: 'Russian', value: 'russian' },
  { label: 'Serbian', value: 'serbian' },
  { label: 'Sinhala', value: 'sinhala' },
  { label: 'Slovak', value: 'slovak' },
  { label: 'Slovenian', value: 'slovenian' },
  { label: 'Somali', value: 'somali' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Swahili', value: 'swahili' },
  { label: 'Swedish', value: 'swedish' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Thai', value: 'thai' },
  { label: 'Tok Pisin', value: 'tok pisin' },
  { label: 'Turkish', value: 'turkish' },
  { label: 'Ukrainian', value: 'ukrainian' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Uzbek', value: 'uzbek' },
  { label: 'Vietnamese', value: 'vietnamese' },
  { label: 'Xhosa', value: 'xhosa' },
  { label: 'Zulu', value: 'zulu' }
]

export default languages
