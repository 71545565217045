import React from 'react'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank
} from '@material-ui/icons'

// const limit = 8
const Leads = ({ selectedLeads, contactsLists, handleLeadsSelection }) => {
  const { t } = useTranslation()

  return (
    <div>
      <TableContainer mb={6} id="clientsTable">
        <Thead>
          <Th>{t('NAME')}</Th>
          <Th center>{t('CONTACTS')}</Th>
          <Th className="w-32" center>
            {t('SELECT')}
          </Th>
        </Thead>
        <Tbody>
          {contactsLists.loading ? (
            <TableDataLoading cols={6} />
          ) : contactsLists.data.length > 0 ? (
            contactsLists.data.map((item, index) => (
              <Row
                t={t}
                key={index}
                list={item}
                isChecked={selectedLeads.includes(item._id)}
                onChange={e => handleLeadsSelection(item._id, e.target.checked)}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                {t('NO_DATA_AVAILABLE')}
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
    </div>
  )
}

const Row = ({ list, isChecked, onChange }) => {
  return (
    <Tr>
      <Td>{list.name}</Td>
      <Td className="text-center">{list.contactCount}</Td>
      <Td center>
        <Checkbox
          onChange={onChange}
          checked={isChecked}
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={
            <CheckBoxIcon fontSize="small" className="dark:text-dark-light" />
          }
        />
      </Td>
    </Tr>
  )
}

export default Leads
