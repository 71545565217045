import React, { useState, useEffect } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import { useTranslation } from 'react-i18next'
import {
  createContactsList,
  updateContactsListById
} from 'thunks/contacts/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const CreateListPanel = ({
  open,
  onClose,
  data,
  addNewContactsList,
  updateContactsList
}) => {
  const { t } = useTranslation()

  return (
    <CustomDrawer onClose={onClose} anchor="right" open={open}>
      <DrawerHeader onClose={onClose}>
        {data._id ? `${t('EDIT')} ${t('LIST')}` : t('CREATE_NEW_LIST')}
      </DrawerHeader>

      <PanelContent
        onClose={onClose}
        data={data}
        t={t}
        addNewContactsList={addNewContactsList}
        updateContactsList={updateContactsList}
      />
    </CustomDrawer>
  )
}

const PanelContent = ({
  onClose,
  data,
  t,
  addNewContactsList,
  updateContactsList
}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    name: data.name,
    description: data.description
  })
  const [loading, setLoading] = useState(false)

  const handleAddList = () => {
    setLoading(true)
    createContactsList({ body: formData }, (res, err) => {
      setLoading(false)
      if (err) dispatch(fireErrorToaster(res))
      else {
        addNewContactsList(res)
        dispatch(fireSuccessToaster(t('SUCCESS')))
        onClose()
      }
    })
  }

  const handleUpdateList = () => {
    setLoading(true)
    updateContactsListById({ listId: data._id, body: formData }, (res, err) => {
      setLoading(false)

      if (err) dispatch(fireErrorToaster(res))
      else {
        updateContactsList(res)
        dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
        onClose()
      }
    })
  }

  return (
    <>
      <DrawerContent open={true}>
        <div className="mb-4 mt-2">
          <FormLabel required>
            {t('LIST')} {t('NAME')}
          </FormLabel>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={e =>
              setFormData(prev => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
      </DrawerContent>

      <DrawerFooter>
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
          className="w-full"
        >
          {t('CANCEL')}
        </Button>

        <LoadingButton
          size="large"
          loading={loading}
          onClick={data?._id ? handleUpdateList : handleAddList}
          className="w-full"
        >
          {data?._id ? t('SAVE_CHANGES') : t('CREATE')}
        </LoadingButton>
      </DrawerFooter>
    </>
  )
}

export default CreateListPanel
