import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Badge } from '@material-ui/core'
import { getUserPath } from 'utils/userRoles'
import { AlertModal, Avatar, PopupMenuList } from 'global/globalComponents'
import { dateAndTimeHelper } from 'utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as EditIcon } from 'static/svg/edit.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as ArchiveIcon } from 'static/svg/archive.svg'
import socket from 'config/socketConfig'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { deleteGroup, updateGroup } from 'thunks/chat/actions'
import { useHistory } from 'react-router-dom'
import { CHANNEL_TYPE } from './chatStatic'
import UpdateChannelModal from './UpdateChannelModal'
import StyledBadge from 'global/globalComponents/StyledBadge/StyledBadge'
import stringToHslColor from 'utils/stringToHslColor'

const ChannelsListItem = ({
  channelId,
  channelName,
  channelType,
  chatPermission,
  currentChannelId,
  unreadMessageData = {},
  avatarUser,
  isPersonalChannel,
  isArchived,
  handleOnClick
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const [lastMessage, setLastMessage] = useState({ text: '', createdAt: null })
  const [showOptions, setShowOptions] = useState(false)
  const [modal, setModal] = useState({ name: '', loading: false })
  const isActive = currentChannelId === channelId

  useEffect(() => {
    if (unreadMessageData.lastUnreadMessage) {
      const elm = document.createElement('div')
      elm.innerHTML = unreadMessageData.lastUnreadMessage.message
      setLastMessage({
        text: elm.textContent,
        createdAt: unreadMessageData.lastUnreadMessage.createdAt
      })
    }
  }, [unreadMessageData.lastUnreadMessage])

  const openModal = ({ name }) => {
    setModal({ name, loading: false })
  }

  const closeModal = () => {
    setModal(prev => ({ ...prev, name: '' }))
  }

  const toggleLoading = () => {
    setModal(prev => ({ ...prev, loading: !prev.loading }))
  }

  const handleDeleteChannel = () => {
    toggleLoading()

    socket.emit(
      'deleteGroup',
      {
        id: channelId
      },
      ({ status, result, error }) => {
        // empty callback
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
          toggleLoading()
        } else {
          dispatch(deleteGroup(channelId))
          dispatch(fireSuccessToaster(t('CHANNEL_DELETED')))

          if (channelId === currentChannelId) {
            history.push(`${getUserPath(meData.role)}/chat-stream`)
          } else {
            closeModal()
          }
        }
      }
    )
  }

  const handleToggleArchiveState = () => {
    toggleLoading(true)

    socket.emit(
      'updateGroup',
      {
        id: channelId
      },
      {
        isArchive: !isArchived
      },
      ({ status, result, error }) => {
        if (status !== 200) {
          dispatch(
            fireErrorToaster(error?.message || t('ERROR_SOME_ERROR_OCCURED'))
          )
          toggleLoading(false)
        } else {
          dispatch(updateGroup(result))
          dispatch(fireSuccessToaster(t('CHANGES_SAVED_SUCCESSFULLY')))
          closeModal()
        }
      }
    )
  }

  let lastMessageSender = ''

  if (unreadMessageData.lastUnreadMessage?.createdBy?.name) {
    if (unreadMessageData.lastUnreadMessage.createdBy._id === meData._id)
      lastMessageSender = 'You:'
    else {
      lastMessageSender = `${
        unreadMessageData.lastUnreadMessage.createdBy.name.split(' ')[0]
      }:`
    }
  }

  return (
    <>
      <li
        className={clsx(
          'flex justify-between items-center text-gray-900 mb-2 hover:bg-gray-100 dark:hover:text-dark-light dark:hover:bg-dark-main3',
          isActive && 'bg-gray-100 dark:bg-dark-main3'
        )}
        onMouseEnter={
          isPersonalChannel || !chatPermission.edit
            ? undefined
            : () => setShowOptions(true)
        }
        onMouseLeave={
          isPersonalChannel || !chatPermission.edit
            ? undefined
            : () => setShowOptions(false)
        }
      >
        <div
          onClick={handleOnClick}
          // isActive={(match, location) => {
          //   return location.pathname.endsWith(`/${channelId}`)
          // }}
          // to={
          //   isPersonalChannel
          //     ? `${getUserPath(
          //         meData.role
          //       )}/chat-stream/personal-chat/${channelId}`
          //     : `${getUserPath(meData.role)}/chat-stream/${channelId}`
          // }
          className="cursor-pointer text-sm w-full py-1.5 pl-4 dark:text-white font-semibold"
          // activeClassName="bg-gray-100 dark:bg-dark-main3"
        >
          <div className="flex items-center gap-2">
            {avatarUser ? (
              <div className="w-8 h-8 bg-gray-200 text-gray-600 flex justify-center items-center rounded-full flex-shrink-0">
                <Avatar
                  user={avatarUser}
                  // user={
                  //   channel.members?.find(item => item.role === 'moderator')
                  //     ?.user || {}
                  // }
                  tooltip={false}
                  size="full"
                />
              </div>
            ) : (
              <div
                style={{ backgroundColor: stringToHslColor(channelName) }}
                className="w-10 h-10 bg-gray-200 dark:bg-dark-main text-gray-600 flex justify-center items-center rounded-full flex-shrink-0"
              >
                <span className="text-base font-normal text-white">#</span>
              </div>
            )}
            <div className="flex-1">
              <div
                className="flex items-center dark:text-dark-light"
                title={channelName}
              >
                <span className="w-2/3 truncate">{channelName}</span>

                {lastMessage.createdAt && (
                  <div className="ml-auto flex flex-col items-center">
                    <span className="text-xs font-medium text-gray-500">
                      {dateAndTimeHelper.fromNow(lastMessage.createdAt)}
                    </span>

                    {Boolean(unreadMessageData.unreadCount) && (
                      <StyledBadge
                        color="success"
                        badgeContent={unreadMessageData.unreadCount}
                        max={9}
                        style={{ top: 12 }}
                      />
                    )}
                  </div>
                )}
              </div>
              {unreadMessageData.lastUnreadMessage && (
                <div className="flex items-center text-gray-500 font-normal text-xs mt-1">
                  {lastMessageSender && (
                    <div className="mr-1">{lastMessageSender}</div>
                  )}
                  <div className="w-40 truncate">{lastMessage.text}</div>
                </div>
              )}
            </div>

            {/* Personal channel cannot be edited or deleted, because they don't exist */}
            <div className="w-6">
              {showOptions && (
                <OptionsButton
                  onDelete={() => openModal({ name: 'deleteChannel' })}
                  onArchive={() => openModal({ name: 'archiveChannel' })}
                  onEdit={() => openModal({ name: 'editChannel' })}
                  isArchived={isArchived}
                  isDeletable={
                    chatPermission.delete &&
                    [
                      CHANNEL_TYPE.INTERNAL,
                      CHANNEL_TYPE.WORKSPACE,
                      CHANNEL_TYPE.PUBLIC_CHAT
                    ].includes(channelType)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </li>

      <AlertModal
        open={modal.name === 'deleteChannel'}
        loading={modal.loading}
        warningText={t('WARNING_THE_SELECTED_CHANNEL_WILL_BE_DELETED', {
          data: channelName
        })}
        deleteBtnText={t('DELETE')}
        handleDialog={closeModal}
        handleDeleteAction={handleDeleteChannel}
      />

      <AlertModal
        open={modal.name === 'archiveChannel'}
        isDanger={false}
        loading={modal.loading}
        warningText={t('ARCHIVE_CHANNEL_WARNING', {
          action: isArchived ? t('UNARCHIVE') : t('ARCHIVE'),
          channelName: channelName
        })}
        deleteBtnText={t('YES')}
        handleDialog={closeModal}
        handleDeleteAction={handleToggleArchiveState}
      />

      <UpdateChannelModal
        open={modal.name === 'editChannel'}
        onClose={closeModal}
        data={{ channelId }}
      />
    </>
  )
}

const OptionsButton = ({
  isDeletable,
  onDelete,
  onArchive,
  isArchived,
  onEdit
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMethod = (value, e) => {
    e.preventDefault()
    e.stopPropagation()

    if (value === 'delete') onDelete()
    if (value === 'archive') onArchive()
    if (value === 'edit') onEdit()
  }

  const handleTogglePopup = e => {
    e.preventDefault()
    e.stopPropagation()

    if (anchorEl) setAnchorEl(null)
    else setAnchorEl(e.currentTarget)
  }

  const menuItems = [
    {
      label: t('EDIT'),
      icon: <EditIcon />,
      value: 'edit',
      method: handleMethod
    },
    {
      label: isArchived ? t('UNARCHIVE') : t('ARCHIVE'),
      icon: <ArchiveIcon />,
      value: 'archive',
      method: handleMethod
    }
  ]

  if (isDeletable) {
    menuItems.push({
      label: t('DELETE'),
      icon: <TrashIcon />,
      value: 'delete',
      method: handleMethod,
      isDanger: true
    })
  }

  return (
    <>
      <button onClick={handleTogglePopup}>
        <VerticalThreeDot className="w-4 h-4" />
      </button>
      <PopupMenuList
        anchorEl={anchorEl}
        menuItems={menuItems}
        onClose={handleTogglePopup}
        zIndex={9999999}
      />
    </>
  )
}

export default ChannelsListItem
