import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Adds email account
 * @param {Object} data.body
 * * @param {Function} callback
 */
export const addEmailAccount = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/connect-email/test/imap',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Fetches all email accounts
 * * @param {Function} callback
 */
export const fetchAllEmailAccounts = catchAsync(async callback => {
  const res = await axios({
    method: 'GET',
    url: '/connect-email/user'
  })

  if (callback) callback(res.data)
})

/**
 * Updates email account by id
 * * @param {String} data.accountId
 * * @param {Object} data.body
 * * @param {Function} callback
 */
export const updateEmailAccountById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PATCH',
    url: `/connect-email/${data.accountId}`,
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Deletes email account by id
 * * @param {Function} callback
 */
export const deleteEmailAccount = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/connect-email/${data.accountId}`
  })

  if (callback) callback(res.data)
})
