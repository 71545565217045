import React, { useState, useEffect } from 'react'
import { Button, TextField, useTheme } from '@material-ui/core'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { AlertModal, LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useTranslation } from 'react-i18next'
import languages from 'constants/languages'
import { Add } from '@material-ui/icons'
import { createContact, updateContactById } from 'thunks/contacts/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useModal } from 'customHooks'

const AddContactPanel = ({
  open,
  onClose,
  data,
  addNewContact,
  updateContact,
  contactsLists
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    modal: alertModal,
    openModal: openAlertModal,
    closeModal: closeAlertModal,
    toggleLoading
  } = useModal()

  const handleUnsubscribe = () => {
    toggleLoading()
    updateContactById(
      {
        contactId: alertModal.data._id,
        body: { subscribed: !alertModal.data.subscribed }
      },
      (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          toggleLoading()
        } else {
          updateContact(res)
          dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
          closeAlertModal()
          onClose()
        }
      }
    )
  }

  return (
    <CustomDrawer onClose={onClose} anchor="right" open={open}>
      <DrawerHeader
        onClose={onClose}
        rightElement={
          data._id ? (
            <Button
              style={
                data.subscribed
                  ? { backgroundColor: '#dc2626', borderColor: '#dc2626' }
                  : undefined
              }
              onClick={() => openAlertModal(data)}
            >
              {data.subscribed ? t('UNSUBSCRIBE') : t('SUBSCRIBE')}
            </Button>
          ) : null
        }
      >{`${data._id ? t('EDIT') : t('ADD')} ${t('CONTACT')}`}</DrawerHeader>

      <PanelContent
        onClose={onClose}
        data={data}
        t={t}
        addNewContact={addNewContact}
        updateContact={updateContact}
        contactsLists={contactsLists}
      />

      <AlertModal
        open={alertModal.open}
        loading={alertModal.loading}
        warningText={
          alertModal.data.subscribed
            ? t('UNSUBSCRIBE_CONTACT_WARNING')
            : t('SUBSCRIBE_CONTACT_WARNING')
        }
        deleteBtnText={
          alertModal.data.subscribed ? t('UNSUBSCRIBE') : t('SUBSCRIBE')
        }
        handleDialog={closeAlertModal}
        isDanger={alertModal.data.subscribed}
        handleDeleteAction={handleUnsubscribe}
      />
    </CustomDrawer>
  )
}

const PanelContent = ({
  onClose,
  data,
  t,
  addNewContact,
  updateContact,
  contactsLists
}) => {
  const muiTheme = useTheme()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    verified: false,
    subscribed: true,
    country: '',
    state: '',
    language: '',
    personalization: '',
    personalization_a: '',
    personalization_b: '',
    personalization_c: '',
    personalization_d: '',
    campaignList: []
  })
  const [selectedLists, setSelectedLists] = useState([])
  const [listOptions, setListOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [
    addedPersonalizationFieldsCount,
    setAddedPersonalizationFieldsCount
  ] = useState(0)

  useEffect(() => {
    if (data?._id) {
      setFormData({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        verified: data.verified,
        subscribed: data.subscribed,
        country: data.country,
        state: data.state,
        language: data.language,
        personalization: data.personalization,
        personalization_a: data.personalization_a || '',
        personalization_b: data.personalization_b || '',
        personalization_c: data.personalization_c || '',
        personalization_d: data.personalization_d || ''
      })

      let count = 0
      for (let key in data) {
        if (key.includes('personalization_')) count++
      }

      setAddedPersonalizationFieldsCount(count)
    }
  }, [data])

  useEffect(() => {
    if (data.campaignList) {
      const selectedListsMapped = data.campaignList
        .filter(listId => {
          const idx = contactsLists.findIndex(c => c._id === listId)
          return idx !== -1
        })
        .map(listId => {
          const match = contactsLists.find(c => c._id === listId)
          return match
        })

      setSelectedLists(selectedListsMapped)
    }
  }, [data.campaignList, contactsLists])

  useEffect(() => {
    setListOptions(contactsLists)
  }, [contactsLists])

  const handleAddContact = () => {
    setLoading(true)
    createContact(
      {
        body: { ...formData, campaignList: selectedLists.map(item => item._id) }
      },
      (res, err) => {
        setLoading(false)
        if (err) dispatch(fireErrorToaster(res))
        else {
          addNewContact(res)
          dispatch(fireSuccessToaster(t('SUCCESS')))
          onClose()
        }
      }
    )
  }

  const handleUpdateContact = () => {
    setLoading(true)
    updateContactById(
      {
        contactId: data._id,
        body: { ...formData, campaignList: selectedLists.map(item => item._id) }
      },
      (res, err) => {
        setLoading(false)
        if (err) dispatch(fireErrorToaster(res))
        else {
          updateContact(res)
          dispatch(fireSuccessToaster(t('SUCCESS')))
          onClose()
        }
      }
    )
  }

  const formInputHandler = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <>
      <DrawerContent open={true}>
        <div className="mb-4 mt-2">
          <FormLabel required>{t('EMAIL_ADDRESS')}</FormLabel>
          <TextField
            fullWidth
            name="email"
            value={formData.email}
            type="email"
            placeholder={t('ENTER_EMAIL')}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('LIST')}</FormLabel>
          <Select
            value={selectedLists}
            options={listOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            styles={reactSelectCustomStyles(muiTheme)}
            className="w-full"
            maxMenuHeight={150}
            isMulti
            onChange={setSelectedLists}
            // noOptionsMessage={({ inputValue }) => {
            //   return (
            //     <span
            //       className="text-md text-blue-700 dark:text-blue-400 text-left cursor-pointer flex items-center"
            //       onClick={() => handleCreateList(inputValue)}
            //     >
            //       <AddIcon fontSize="small" className="mr-2" />
            //       {t('ADD')} {inputValue && `'${inputValue}'`}
            //     </span>
            //   )
            // }}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('FIRST_NAME')}</FormLabel>
          <TextField
            name="firstName"
            fullWidth
            placeholder={t('FIRST_NAME')}
            value={formData.firstName}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('LAST_NAME')}</FormLabel>
          <TextField
            name="lastName"
            fullWidth
            placeholder={t('LAST_NAME')}
            value={formData.lastName}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel>{t('PERSONALIZATION')}</FormLabel>
          <TextField
            name="personalization"
            fullWidth
            placeholder={t('TEXT')}
            value={formData.personalization}
            onChange={formInputHandler}
          />

          {new Array(addedPersonalizationFieldsCount)
            .fill('')
            .map((item, index) => (
              <div key={index} className="mt-4">
                <FormLabel>
                  {t('PERSONALIZATION')}{' '}
                  {personalizationFields[index].toUpperCase()}
                </FormLabel>
                <TextField
                  name="personalization"
                  fullWidth
                  placeholder={t('TEXT')}
                  value={
                    formData[`personalization_${personalizationFields[index]}`]
                  }
                  onChange={e =>
                    setFormData(prev => ({
                      ...prev,
                      [`personalization_${personalizationFields[index]}`]: e
                        .target.value
                    }))
                  }
                />
              </div>
            ))}

          {addedPersonalizationFieldsCount < 4 && (
            <div className="flex justify-end mt-2">
              <button
                onClick={() =>
                  setAddedPersonalizationFieldsCount(prev => prev + 1)
                }
                className="w-5 h-5 bg-primary-main text-white rounded inline-flex justify-center items-center"
              >
                <Add fontSize="small" />
              </button>
            </div>
          )}
        </div>

        <div className="mb-4">
          <FormLabel>{t('PHONE_NUMBER')}</FormLabel>
          <TextField
            name="phone"
            fullWidth
            placeholder={t('PHONE_NUMBER')}
            value={formData.phone}
            onChange={formInputHandler}
          />
        </div>

        <div className="mb-4">
          <FormLabel>
            {t('CHOOSE')} {t('LANGUAGE')}
          </FormLabel>
          <Select
            value={languages.find(item => item.value === formData.language)}
            isSearchable={true}
            options={languages}
            onChange={option =>
              setFormData(prev => ({ ...prev, language: option.value }))
            }
            styles={reactSelectCustomStyles(muiTheme)}
          />
        </div>

        <div className="mb-4">
          <FormLabel>
            {t('CHOOSE')} {t('COUNTRY')}
          </FormLabel>
          <Select
            isSearchable={false}
            value={countries.find(item => item.value === formData.country)}
            options={countries}
            onChange={option =>
              setFormData(prev => ({ ...prev, country: option.value }))
            }
            styles={reactSelectCustomStyles(muiTheme)}
            menuPlacement="top"
          />
        </div>

        <div className="mb-4">
          <FormLabel>
            {t('CHOOSE')} {t('STATE')}
          </FormLabel>
          <Select
            isSearchable={false}
            value={states.find(item => item.value === formData.state)}
            options={states}
            onChange={option =>
              setFormData(prev => ({ ...prev, state: option.value }))
            }
            styles={reactSelectCustomStyles(muiTheme)}
            menuPlacement="top"
          />
        </div>
      </DrawerContent>

      <DrawerFooter>
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
          className="w-full"
        >
          {t('CANCEL')}
        </Button>

        <LoadingButton
          size="large"
          loading={loading}
          onClick={data._id ? handleUpdateContact : handleAddContact}
          className="w-full"
        >
          {data._id ? t('SAVE_CHANGES') : t('ADD')}
        </LoadingButton>
      </DrawerFooter>
    </>
  )
}

const countries = [
  { label: 'India', value: 'india' },
  { label: 'Nepal', value: 'nepal' },
  { label: 'Pakistan', value: 'pakistan' },
  { label: 'Bangladesh', value: 'bangladesh' }
]

const states = [
  { label: 'Delhi', value: 'delhi' },
  { label: 'Uttar Paradesh', value: 'utter pradesh' },
  { label: 'Bihar', value: 'bihar' },
  { label: 'Bengal', value: 'bengal' }
]

const personalizationFields = ['a', 'b', 'c', 'd']

// const fetchAllCountries = async () => {
//   const res = await axios('https://restcountries.com/v3.1/all')
//   console.log({ res })
// }

// fetchAllCountries()

export default AddContactPanel
