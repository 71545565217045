import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Creates Campaign
 * @param {Object} data.body
 * @param {Function} callback function (optional)
 */
export const createCampaign = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/campaign',
    method: 'POST',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Fetched all Campaigns
 * @param {Object} data.body
 * @param {Function} callback function (optional)
 */
export const fetchAllCampaigns = catchAsync(async callback => {
  const res = await axios({
    url: '/campaign/user',
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Deletes campaign by ID
 * @param {string} data.campaignId
 * @param {Function} callback function (optional)
 */
export const deleteCampaign = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/campaign/${data.campaignId}`,
    method: 'DELETE'
  })

  if (callback) callback(res.data)
})

/**
 * Updates campaign's config field
 * @param {string} data.campaignId
 * @param {object} data.body update
 * @param {Function} callback function (optional)
 */
export const updateCampaignConfig = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/campaign/config/${data.campaignId}`,
    method: 'PATCH',
    data: data.body
  })

  if (callback) callback(res.data)
})
