import React from 'react'
import { getTemplatesMeta } from 'components/_shared/ServiceTemplates/helper'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Skeleton } from '@material-ui/lab'
import { AlertModal, PopupMenu } from 'global/globalComponents'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
// import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as MinusCircleIcon } from 'static/svg/minus-circle.svg'
import {
  deleteServiceTemplateById,
  updateServiceTemplateById
} from 'thunks/service/actions'
import { useModal } from 'customHooks'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Pill from 'global/globalComponents/Pill/Pill'
import { Button } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

const ServiceTemplates = () => {
  return (
    <div>
      <div className="mb-8">
        <SavedTemplates />
      </div>

      <div className="mb-8">
        <TemplatesLibrary />
      </div>
    </div>
  )
}

const SavedTemplates = () => {
  const dispatch = useDispatch()
  const { loading, data: fetchedTemplateData } = useSelector(
    state => state.userServices.serviceTemplates
  )
  const {
    modal: activeTemplateAlert,
    openModal: openActiveTemplateAlert,
    closeModal: closeActiveTemplateAlert,
    toggleLoading
  } = useModal()
  const {
    modal: deleteTemplateAlert,
    openModal: openDeleteTemplateAlert,
    closeModal: closeDeleteTemplateAlert,
    toggleLoading: toggleDeleteTemplateLoading
  } = useModal()

  const handleActivateTemplate = () => {
    const activeState = !activeTemplateAlert.data.isActive

    if (activeState) {
      // Deactivate the current active template
      const currentActiveTemplate = fetchedTemplateData.find(
        temp => temp.isActive
      )

      if (currentActiveTemplate) {
        dispatch(
          updateServiceTemplateById({
            id: currentActiveTemplate._id,
            body: { isActive: false }
          })
        )
      }
    }

    toggleLoading()
    dispatch(
      updateServiceTemplateById(
        {
          id: activeTemplateAlert.data._id,
          body: { isActive: activeState }
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
            closeActiveTemplateAlert()
          }
        }
      )
    )
  }

  const handleDeleteTemplate = () => {
    toggleDeleteTemplateLoading()
    dispatch(
      deleteServiceTemplateById(
        { id: deleteTemplateAlert.data._id },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            toggleLoading()
          } else {
            dispatch(fireSuccessToaster(t('TEMPLATE_DELETED_SUCCESSFULLY')))
            closeDeleteTemplateAlert()
          }
        }
      )
    )
  }

  const getCardOptions = template => {
    return [
      {
        label: template.isActive ? t('DEACTIVATE') : t('ACTIVATE'),
        icon: template.isActive ? (
          <MinusCircleIcon className="w-4 h-4" />
        ) : (
          <CheckCircleIcon className="w-4 h-4" />
        ),
        method: () => openActiveTemplateAlert(template)
      },
      {
        label: t('DELETE'),
        icon: <TrashIcon className="w-4 h-4" />,
        method: () => openDeleteTemplateAlert(template),
        isDanger: true
      }
    ]
  }

  if (!loading && fetchedTemplateData.length === 0) return null

  return (
    <>
      <h2 className="font-semibold mb-4 capitalize">{t('SAVED_TEMPLATES')}</h2>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 250px)',
          gridGap: '1rem',
          gap: '1rem'
        }}
      >
        {loading
          ? new Array(3)
              .fill('')
              .map((item, index) => (
                <Skeleton key={index} variant="rect" height={300} />
              ))
          : fetchedTemplateData.map(template => (
              <TemplateCard
                key={template._id}
                label={template.configuration.label}
                description={template.description}
                image={template.configuration.image}
                isActive={template.isActive}
                {...template}
                path={`/agency/payments/service-template/${template._id}/edit`}
                cardOptions={getCardOptions(template)}
                isEditable={true}
              />
            ))}
      </div>

      <AlertModal
        open={activeTemplateAlert.open}
        heading={t('ARE_YOU_SURE')}
        warningText={t(
          activeTemplateAlert.data.isActive
            ? 'SERVICE_TEMPLATE_DEACTIVATE_MESSAGE'
            : 'SERVICE_TEMPLATE_ACTIVATE_MESSAGE',
          {
            templateName: activeTemplateAlert.data.name
          }
        )}
        cancelText={t('CANCEL')}
        deleteBtnText={t('YES')}
        isDanger={false}
        handleDialog={closeActiveTemplateAlert}
        handleDeleteAction={handleActivateTemplate}
        loading={activeTemplateAlert.loading}
      />

      <AlertModal
        open={deleteTemplateAlert.open}
        heading={t('ARE_YOU_SURE')}
        warningText={t('WARNING_FILE_DELETE', {
          data: deleteTemplateAlert.data.name
        })}
        cancelText={t('CANCEL')}
        deleteBtnText={t('YES')}
        handleDialog={closeDeleteTemplateAlert}
        handleDeleteAction={handleDeleteTemplate}
        loading={deleteTemplateAlert.loading}
      />
    </>
  )
}

const TemplatesLibrary = () => {
  const templatesMetaData = getTemplatesMeta()

  return (
    <>
      <h2 className="font-semibold mb-4 capitalize">
        {t('TEMPLATES_LIBRARY')}
      </h2>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 250px)',
          gridGap: '1rem',
          gap: '1rem'
        }}
      >
        {templatesMetaData.map((template, index) => (
          <TemplateCard
            key={index}
            label={template.label}
            description={template.description}
            image={template.image}
            path={`/agency/payments/service-template/${template.name}`}
          />
        ))}
      </div>
    </>
  )
}

const TemplateCard = ({
  label,
  description,
  image,
  path,
  isActive,
  cardOptions,
  isEditable
}) => {
  const history = useHistory()

  const handleOnClick = () => {
    history.push(path)
  }

  return (
    <div className="p-4 bg-white border rounded-md hover:shadow-md transition-all show-on-hover-parent">
      <div className="bg-gray-100 h-40 rounded-md mb-4 relative">
        {Boolean(cardOptions) && (
          <div className="absolute right-0 top-0">
            <PopupMenu
              targetComp={
                <button
                  style={{ background: 'rgba(0, 0, 0, 0.75)' }}
                  className="w-7 h-7 rounded-full flex justify-center items-center bg-gray-800 text-white show-on-hover-child"
                >
                  <ThreeDotsIcon className="w-3 h-3" />
                </button>
              }
              menuItems={cardOptions}
            />
          </div>
        )}
        <img src={image} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="font-medium text-sm mb-1 flex items-center">
        <span
          className="mr-2 cursor-pointer hover:text-primary-main"
          onClick={handleOnClick}
        >
          {label}
        </span>
        {isActive && <Pill variant="green">{t('ACTIVE')}</Pill>}
      </div>
      <div className="text-smaller text-gray-600 mb-4">{description}</div>
      <footer className="flex justify-end">
        <Button endIcon={<ChevronRight />} onClick={handleOnClick}>
          {isEditable ? t('EDIT') : t('PREVIEW')}
        </Button>
      </footer>
    </div>
  )
}

export default ServiceTemplates
