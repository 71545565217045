import React, { useState, useEffect, useRef, useMemo } from 'react'
import Pill from 'global/globalComponents/Pill/Pill'
import clsx from 'clsx'
import { ReactComponent as Repeat } from 'static/svg/repeat.svg'
import { ReactComponent as VerticalThreeDots } from 'static/svg/ellipsis-h.svg'
import taskSidePanelStyles from './taskSidePanelStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import {
  CustomFlag,
  OutlinedButton,
  PopupMenu,
  AlertModal
  // LoadingButton,
  // Loader
} from 'global/globalComponents'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import EstimateTimePopup from './EstimateTimePopup'
import PanelTimer from 'components/Timer/PanelTimer'
import { userRoles, adjustColor } from 'utils'
import { CircularProgress, Toolbar, useTheme } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as UserCheckIcon } from 'static/svg/user-check.svg'
import { ReactComponent as CheckIcon } from 'static/svg/checks.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { useTranslation } from 'react-i18next'
import {
  duplicateTask,
  updateTaskDetails,
  updateTaskPriority,
  updateTaskSection
} from 'thunks/task/actions'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import StatusDropDown from '../StatusDropDown'
import {
  updateSubTaskDetails,
  updateSubTaskPriority
} from 'thunks/subTask/actions'
import RecurringDropDown from '../RecurringDropDown'
import CostPopup from './CostPopup'

const TaskPanelHeader = ({
  task,
  onClose,
  elmFor, // using this header for 2 comp => task and subtask
  handleAttachmentUpload,
  handleOpenDeleteTaskConfirm,
  innerProjectPermission,
  changeEstimateTime,
  currentBoard,
  canUpdateTask,
  inDashboardArea,
  openApprovalPanel,
  updateTaskData,
  currency
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const taskStatus = useMemo(() => {
    let taskSectionId =
      typeof task.taskSection === 'object'
        ? task.taskSection._id
        : task.taskSection
    if (currentBoard.boardSection) {
      return currentBoard.boardSection.find(item => item._id === taskSectionId)
    }
  }, [task.taskSection, currentBoard.boardSection])
  const [menuOptions, setMenuOptions] = useState([])
  const inputRef = useRef()
  const [selectedStatus, setSelectedStatus] = useState({ label: '', color: '' })
  const [esTimeAnchor, setEsTimeAnchor] = useState(null)
  const [selectedTime, setSelectedTime] = useState('')
  const dispatch = useDispatch()
  const classes = taskSidePanelStyles()
  const meData = useSelector(state => state.me.data)
  const [alertModal, setAlertModal] = useState({
    loading: false,
    name: ''
  })
  const [popper, setPopper] = useState({
    name: '',
    anchorEl: null
  })

  useEffect(() => {
    if (task.estimateTimeInString) {
      setSelectedTime(task.estimateTimeInString)
    }
  }, [task.estimateTimeInString])

  useEffect(() => {
    if (taskStatus) {
      setSelectedStatus({
        label: taskStatus.label,
        color: taskStatus.color
      })
    }
  }, [taskStatus])

  useEffect(() => {
    const arr = [
      { label: t('COPY_LINK'), method: handleCopyLink, icon: <CopyIcon /> }
    ]

    if (innerProjectPermission.update) {
      arr.push({
        label: t('UPLOAD_IMAGE'),
        method: handleImageUploadClick,
        icon: <DownloadIcon className="rotate-180" />
      })
    }
    if (innerProjectPermission.create && elmFor === 'task') {
      arr.push({
        label: t('DUPLICATE_TASK'),
        method: () => handleOpenAlert('duplicateTask'),
        icon: <FileIcon />
      })
    }

    if (innerProjectPermission.delete) {
      arr.push({
        label: t('DELETE'),
        method: handleOpenDeleteTaskConfirm,
        icon: <TrashIcon />,
        isDanger: true
      })
    }

    setMenuOptions(arr)
  }, [innerProjectPermission, elmFor])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    dispatch(fireSuccessToaster(t('LINK_COPIED')))
  }

  const handleChangePriority = e => {
    if (canUpdateTask(innerProjectPermission.update)) {
      handleOpenPopper(e.currentTarget, 'priority')
    }
  }

  const handleUploadImage = async e => {
    if (canUpdateTask(innerProjectPermission.update)) {
      const file = e.target.files[0]
      handleAttachmentUpload(file)
      // if (file) {
      //   const res = await fileToDataUrl(file)
      //   handleAttachmentUpload(res)
      // }
    }
  }

  const handleChangeStatus = e => {
    if (canUpdateTask(innerProjectPermission.update)) {
      handleOpenPopper(e.currentTarget, 'status')
    }
  }

  const handleImageUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const toggleEstimateTimePopup = e => {
    if (!innerProjectPermission.update) {
      dispatch(
        fireErrorToaster(t('ERROR_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'))
      )
      return false
    }

    if (meData.role <= userRoles.AGENCY_ADMIN) {
      if (esTimeAnchor) {
        setEsTimeAnchor(null)
      } else {
        setEsTimeAnchor(e.currentTarget)
      }
    }
  }

  const handleChangeEstimateTime = ({ timeStr, totalMs }) => {
    if (timeStr !== '') {
      setSelectedTime(timeStr)

      changeEstimateTime({
        estimateTimeInString: timeStr,
        estimateTimeInMilliSecond: totalMs
      })
      toggleEstimateTimePopup()
    } else {
      changeEstimateTime({
        estimateTimeInString: '',
        estimateTimeInMilliSecond: null
      })
    }
  }

  // const handleToggleComplete = () => {
  //   if (
  //     canUpdateTask(taskPermission.update) &&
  //     meData.role <= userRoles.AGENCY_ADMIN
  //   ) {
  //     markTaskAsComplete(!task.markAsComplete)
  //   }
  // }

  const handleRecurringTask = e => {
    if (
      canUpdateTask(innerProjectPermission.update) &&
      meData.role <= userRoles.AGENCY_ADMIN
    ) {
      handleOpenPopper(e.currentTarget, 'recurring')
      // triggerPopover(e, 'recurring') ===> $
    }
  }

  const handleOpenAlert = name => {
    setAlertModal({ name, loading: false })
  }
  const handleCloseAlert = () => {
    setAlertModal(prev => ({ ...prev, name: '' }))
  }

  const handleDuplicateTask = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(
      duplicateTask(
        {
          taskId: task._id,
          data: {
            title: task.title + ' (copy)',
            priority: task.priority,
            imageUrl: task.imageUrl,
            description: task.description,
            dynamicField: task.dynamicField,
            taskSection: task.taskSection._id,
            workSpace: task.workSpace,
            workspaceBoard: task.workspaceBoard
          }
        },
        (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
          else {
            handleCloseAlert()
            dispatch(fireSuccessToaster(t('CREATED_DUPLICATE_TASK')))
          }
        }
      )
    )
  }

  const handleOpenPopper = (anchorEl, name) => {
    setPopper({ anchorEl, name })
  }

  const handleClosePopper = () => {
    setPopper({ name: '', anchorEl: null })
  }

  const handlePriorityChange = priority => {
    if (!canUpdateTask(innerProjectPermission.update)) return

    let prevPriority = task.priority

    handleClosePopper()
    // updating status (not waiting for response)
    updateTaskData({ ...task, priority: priority.order })

    const callback = (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        // on error set previous priority
        updateTaskData({ ...task, priority: prevPriority })
      }
    }

    if (elmFor === 'task') {
      dispatch(
        updateTaskPriority(
          { taskId: task._id, data: { priority: priority.order } },
          callback
        )
      )
    } else {
      dispatch(
        updateSubTaskPriority(
          { id: task._id, data: { priority: priority.order } },
          callback
        )
      )
    }
  }

  const handleChangeSection = section => {
    if (!canUpdateTask(innerProjectPermission.update)) return
    const prevSection = task.taskSection

    handleClosePopper()
    updateTaskData({ ...task, taskSection: section })

    const data = {
      field: 'taskSection',
      workSpace: task.workSpace,
      taskSection: section._id,
      completedAt:
        section.label.toLowerCase() === 'completed' ? new Date() : null,
      markAsComplete: section.label.toLowerCase() === 'completed'
    }

    const callback = (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        // if error occurs set the previous section
        updateTaskData({ ...task, taskSection: prevSection })
      }
    }

    if (elmFor === 'task') {
      dispatch(updateTaskSection({ taskId: task._id, data }, callback))
    } else {
      dispatch(
        updateSubTaskDetails(
          {
            subtaskId: task._id,
            data
          },
          callback
        )
      )
    }
  }

  const handleRecurringTime = recurringTime => {
    if (canUpdateTask(innerProjectPermission.update)) {
      const prevIsTaskRecurring = task.isTaskRecurring
      const prevSetRecurringTime = task.setRecurringTime

      updateTaskData({
        ...task,
        isTaskRecurring: recurringTime !== 0,
        setRecurringTime: recurringTime
      })

      const data = {
        workSpace: task.workSpace,
        taskSection: task.taskSection._id,
        setRecurringTime: recurringTime,
        isTaskRecurring: recurringTime !== 0,
        field: 'isTaskRecurring'
      }

      handleClosePopper()
      dispatch(
        updateTaskDetails({ taskId: task._id, data }, (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            updateTaskData({
              ...task,
              isTaskRecurring: prevIsTaskRecurring,
              setRecurringTime: prevSetRecurringTime
            })
          }
        })
      )
    }
  }

  const handleMarkTaskAsComplete = () => {
    if (
      canUpdateTask(innerProjectPermission.update) &&
      meData.role <= userRoles.AGENCY_ADMIN
    ) {
      const prevMarkComplete = task.markAsComplete
      let completeSection = {}
      updateTaskData({ ...task, markAsComplete: !task.markAsComplete })

      if (currentBoard.boardSection) {
        completeSection = !task.markAsComplete
          ? currentBoard.boardSection.find(
              item => item.label.toLowerCase() === 'completed'
            )
          : {}
      }

      const data = {
        markAsComplete: !task.markAsComplete,
        field: 'markAsComplete',
        workSpace: task.workSpace,
        completedAt: !task.markAsComplete ? new Date() : null,
        taskSection: completeSection?._id ?? task.taskSection._id
      }

      const callback = (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          updateTaskData({ ...task, markAsComplete: prevMarkComplete })
        }
      }

      if (elmFor === 'subtask') {
        dispatch(
          updateSubTaskDetails(
            {
              subtaskId: task._id,
              data,
              updateField: 'markAsComplete'
            },
            callback
          )
        )
      } else {
        dispatch(updateTaskDetails({ taskId: task._id, data }, callback))
      }
    }
  }

  const handleUpdateCost = cost => {
    dispatch(
      updateTaskDetails(
        {
          taskId: task._id,
          data: {
            cost,
            workSpace: task.workSpace,
            field: 'cost',
            taskSection: task.taskSection._id
          }
        },
        (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
        }
      )
    )
  }

  // const handleSubmitComment = comment => {
  //   // const mentions = comment.ops
  //   //   .filter(item => item.insert.mention)
  //   //   .map(item => item.insert.mention)

  //   const commentData = {
  //     description: comment,
  //     activityType: 'comment',
  //     isExternal: Boolean(meData.role >= userRoles.USER_CLIENT),
  //     workSpace: workspace._id,
  //     workspaceBoard: task.workspaceBoard,
  //     createdAt: new Date(),
  //     updatedAt: new Date(),
  //     user: meData._id,
  //     pComment: logs[logs.length - 1]?._id ?? 0,
  //     // category: elmFor === 'task' ? 'task' : 'subtask',
  //     category: 'task',
  //     attachment: [],
  //     task: task._id,
  //     socketId
  //   }

  //   // if (elmFor === 'subtask') {
  //   //   commentData.subTask = subtaskId
  //   // }

  //   dispatch(
  //     postTaskLog({ data: commentData }, (res, err) => {
  //       // if (callback) callback(res, err)

  //       if (err) {
  //         dispatch(fireErrorToaster(res))
  //       } else {
  //         setTimeout(() => {
  //           scrollToBottom()
  //         }, 500)
  //       }
  //     })
  //   )
  // }

  // const handleCreateMeeting = () => {
  //   if (!zoomLoading) {
  //     setZoomLoading(true)
  //     if (isZoomIntegrated)
  //       dispatch(
  //         createZoomMeeting(
  //           {
  //             topic: task.title ?? t('TASK_MEETING')
  //           },
  //           (res, err) => {
  //             if (err) dispatch(fireErrorToaster(res))
  //             else {
  //               handleSubmitComment(
  //                 {
  //                   ops: [
  //                     { insert: t('JOIN_THE_ZOOM_MEETING') + ': \n' },
  //                     {
  //                       attributes: { link: res.join_url },
  //                       insert: res.join_url
  //                     },
  //                     {
  //                       insert:
  //                         '\n' +
  //                         t('STARTS_ON') +
  //                         ' ' +
  //                         moment(res.start_time).format('MMM Do, h:mm a') +
  //                         '\n'
  //                     },
  //                     {
  //                       insert: t('MEETING_ID') + ': ' + res.id + '\n'
  //                     },
  //                     {
  //                       insert: t('PASSCODE') + ': ' + res.password
  //                     }
  //                   ]
  //                 },
  //                 []
  //               )
  //             }
  //             setZoomLoading(false)
  //           }
  //         )
  //       )
  //     else
  //       integrateNewApp(
  //         {
  //           redirectUrl: window.location.href,
  //           app: 'zoom'
  //         },
  //         (res, err) => {
  //           if (!err) {
  //             // let url = res.url;
  //             // let first = url.slice(0, url.indexOf('roni.clientvenue.com'))
  //             // let last = url.slice(url.indexOf('roni.clientvenue.com' + 20))
  //             // window.open(first + 'localhost:3000' + last)
  //             window.open(res.url)
  //           } else dispatch(fireErrorToaster(res))
  //           setZoomLoading(false)
  //         }
  //       )
  //   }
  // }

  return (
    <>
      <Toolbar onClose={onClose} className={classes.drawerPaper1}>
        <div className={clsx(classes.drawerHeader, 'dark:bg-dark-main3')}>
          <div className="flex overflow-auto items-center py-2 gap-2 sm:gap-0 sm:space-x-2 md:space-x-4 xl:space-x-6">
            <div onClick={handleChangeStatus} className="cursor-pointer">
              {selectedStatus.label ? (
                <Pill
                  style={{
                    color: adjustColor(
                      selectedStatus.color,
                      theme.palette.type === 'light' ? -70 : +140
                    ),
                    backgroundColor:
                      selectedStatus.color +
                      (theme.palette.type === 'light' ? '20' : '40')
                  }}
                  className="capitalize"
                >
                  {selectedStatus.label}
                </Pill>
              ) : (
                <div className="flex items-center px-6 h-7 border border-gray-400 rounded-full">
                  <CircularProgress size={14} />
                </div>
              )}
            </div>

            <div onClick={handleChangePriority} className="leading-3">
              {task.priority && task.priority !== 5 ? (
                <CustomFlag priority={task.priority} />
              ) : (
                <CustomTooltip title={t('SET_PRIORITY')} placement="top">
                  <button
                    className={clsx(
                      meData.role > userRoles.AGENCY_ADMIN &&
                        'cursor-not-allowed ',
                      'text-base text-gray-400 w-6 h-6 md:w-7 md:h-7 2xl:w-8 2xl:h-8 border border-gray-400 border-dashed rounded-full flex justify-center items-center'
                    )}
                  >
                    <FlagOutlined className="text-sm 2xl:text-base" />
                  </button>
                </CustomTooltip>
              )}
            </div>

            {meData.role < userRoles.USER_CLIENT && (
              <PanelTimer
                task={task}
                elmFor={elmFor}
                canUpdate={innerProjectPermission.update}
              />
            )}

            <CustomTooltip title={t('ESTIMATE_TIME')} placement="top">
              <div
                className={clsx('flex items-center space-x-1')}
                onClick={toggleEstimateTimePopup}
              >
                <div
                  className={clsx(
                    'inline-block text-gray-500 dark:text-dark-light',
                    meData.role > userRoles.AGENCY_ADMIN
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  )}
                >
                  <StopWatchIcon />
                </div>
                {task.estimateTimeInString && (
                  <span className="text-xs -mb-1 dark:text-dark-light">
                    {task.estimateTimeInString}
                  </span>
                )}
              </div>
            </CustomTooltip>

            {meData.role < userRoles.USER_CLIENT && elmFor === 'task' && (
              <CostPopup
                taskCost={task.cost}
                currency={currency || 'USD'}
                handleUpdateCost={handleUpdateCost}
                canUpdate={innerProjectPermission.update}
              />
            )}

            {elmFor === 'task' && (
              <CustomTooltip title={t('RECURRING_TASK')} placement="top">
                <label
                  role="button"
                  className={clsx(
                    classes.headerIconContainer,
                    meData.role > userRoles.AGENCY_ADMIN && 'cursor-not-allowed'
                  )}
                  onClick={handleRecurringTask}
                >
                  <Repeat
                    className={clsx(
                      task.isTaskRecurring
                        ? 'text-primary-main dark:text-dark-light'
                        : 'text-gray-500 dark:text-dark-light'
                    )}
                  />
                </label>
              </CustomTooltip>
            )}

            {innerProjectPermission.update && (
              <>
                <div className="flex space-x-1 items-center text-gray-700">
                  <OutlinedButton
                    variant="success"
                    isActive={task.markAsComplete}
                    onClick={handleMarkTaskAsComplete}
                    roundedFull={false}
                    hoverColorChange={false}
                    className={clsx(
                      meData.role >= userRoles.USER_CLIENT
                        ? 'cursor-not-allowed'
                        : ''
                    )}
                    disabled={meData.role >= userRoles.USER_CLIENT}
                  >
                    <CheckIcon />
                    <span className=" ml-2">
                      {task?.markAsComplete
                        ? t('COMPLETED')
                        : t('MARK_COMPLETE')}
                    </span>
                  </OutlinedButton>

                  <input
                    type="file"
                    style={{ display: 'none' }}
                    multiple={false}
                    accept="image/*"
                    ref={inputRef}
                    onChange={handleUploadImage}
                  />
                </div>
              </>
            )}

            <OutlinedButton onClick={openApprovalPanel} roundedFull={false}>
              <UserCheckIcon className="dark:text-dark-light" />
              <span className="ml-2">{t('APPROVALS')}</span>
            </OutlinedButton>

            {/* <div
              onMouseEnter={() => setZoomhovered(true)}
              onMouseLeave={() => setZoomhovered(false)}
              className={`border border-custom-gray-light-3 rounded-full cursor-pointer ${
                !zoomLoading
                  ? ' p-1 hover:border-blue-400 hover:bg-blue-400'
                  : 'h-6 p-0.5 px-1 flex items-center'
              }`}
              onClick={handleCreateMeeting}
            >
              <CustomTooltip title={t('ZOOM_MEETING')} placement="top">
                {zoomLoading ? (
                  <CircularProgress size={14} />
                ) : zoomhovered ? (
                  <Zoom className="h-4 w-auto dark:text-dark-light" />
                ) : (
                  <ZoomOutlined className="h-4 w-auto dark:text-dark-light" />
                )}
              </CustomTooltip>
            </div> */}
          </div>

          <div className="flex items-center space-x-4 ml-2">
            {!inDashboardArea && (
              <PopupMenu
                targetComp={
                  <button className="font-lg text-gray-500">
                    <VerticalThreeDots />
                  </button>
                }
                menuItems={menuOptions}
              />
            )}

            <button
              onClick={onClose}
              className="w-6 h-6 md:w-7 md:h-7 2xl:w-8 2xl:h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
            >
              <CloseIcon className="text-primary-main text-lg 2xl:text-xl dark:text-dark-light" />
            </button>
          </div>
        </div>

        <EstimateTimePopup
          anchorEl={esTimeAnchor}
          open={Boolean(esTimeAnchor)}
          onClose={toggleEstimateTimePopup}
          selectedTime={selectedTime}
          handleChangeEstimateTime={handleChangeEstimateTime}
          style={{ zIndex: 2000 }}
        />
      </Toolbar>

      <AlertModal
        heading={t('ARE_YOU_SURE')}
        warningText={t('WANT_TO_DUPLICATE_TASK')}
        handleDialog={handleCloseAlert}
        open={alertModal.name === 'duplicateTask'}
        handleDeleteAction={handleDuplicateTask}
        archiveButton={false}
        loading={alertModal.loading}
        isDanger={false}
        deleteBtnText={t('YES')}
        style={{ zIndex: 2000 }}
      />

      <PriorityDropDown
        open={popper.name === 'priority'}
        anchorEl={popper.anchorEl}
        onClose={handleClosePopper}
        currentPriority={task.priority}
        onChange={handlePriorityChange}
        style={{ zIndex: 2000 }}
      />
      <StatusDropDown
        open={popper.name === 'status'}
        anchorEl={popper.anchorEl}
        onClose={handleClosePopper}
        changeStatus={handleChangeSection}
        statusList={currentBoard.boardSection}
        taskSection={task.taskSection?._id}
        style={{ zIndex: 2000 }}
      />
      <RecurringDropDown
        open={popper.name === 'recurring'}
        anchorEl={popper.anchorEl}
        onClose={handleClosePopper}
        changeStatus={handleRecurringTime}
        recurring={task.setRecurringTime}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

export default React.memo(TaskPanelHeader)
