import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button } from '@material-ui/core'
import { ReactComponent as CallOutgoingIcon } from 'static/svg/call-outgoing.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch, useLocation } from 'react-router'
import userRoles, { getUserPath } from 'utils/userRoles'
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined'
import BreadCrumbs from './Breadcrumbs/BreadCrumbs'
import HeaderProfileMenu from '../HeaderProfileMenu'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import { setHeaderSearch, toggleChatModal } from 'thunks/generlAppState/actions'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import StartTimerModal from 'components/Timer/StartTimerModal'
import HeaderTimer from 'components/Timer/HeaderTimer'
import LogTimerModal from 'components/Timer/LogTimerModal'
import CreateStandupsModal from 'components/Standups/CreateStandupsModal'
// import CalendarModal from 'components/Settings/ResourceManagement/CalendarModal'
import { ReactComponent as Bell } from 'static/svg/bell.svg'
import { ReactComponent as ChatIcon } from 'static/svg/chat.svg'
import { useTranslation } from 'react-i18next'
import AdvanceGlobalSearch from './AdvanceGlobalSearch/AdvanceGlobalSearch'
import { useCalendly } from 'customHooks'
import { ReactComponent as CalenderIcon } from 'static/svg/calender-icon.svg' // for video recording
import { ReactComponent as BriefcaseIcon } from 'static/svg/briefcase.svg'
import { getMarketplaceDomain } from 'utils'
import NotificationPopper from 'components/Notification/NotificationPopper'
import CryptoJS from 'crypto-js'
import { fetchUserById } from 'thunks/user/actions'
import { AllTasksCalendar } from 'components/_agency'

const GlobalHeader = ({ unreadNotificationsCount }) => {
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const userData = useSelector(state => state.user.data)
  const history = useHistory()
  const { data: timerData } = useSelector(state => state.timer)
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)
  const [timerModalOpen, setTimerModalOpen] = useState(false)
  const [logModalOpen, setLogModalOpen] = useState(false)
  const [standupsModalOpen, setStandupsModalOpen] = useState(false)
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)
  const isNewUser = useMemo(() => {
    if (!meData.createdAt) return false
    if (meData.role !== userRoles.USER_AGENCY) return false

    return (
      Math.round(
        (new Date() - new Date(meData.createdAt)) / (1000 * 60 * 60 * 24)
      ) <= 7
    )
  }, [meData.createdAt, meData.role])
  const { handleCalendlyClick } = useCalendly()
  const [notifAnchorEl, setNotifAnchorEl] = useState(null)

  const goToSubscriptions = () => {
    history.push(`${getUserPath(meData.role)}/settings/subscription`)
  }

  const toggleTimerModal = useCallback(() => {
    setTimerModalOpen(prev => !prev)
  }, [])

  const toggleLogModal = useCallback(() => {
    setLogModalOpen(prev => !prev)
  }, [])

  const toggleStandupsModal = () => {
    setStandupsModalOpen(prev => !prev)
  }

  // const handleUsersPulse = () => {
  //   history.push(`${getUserPath(meData.role)}/pulse`)
  // }

  const handleViewNotifications = e => {
    setNotifAnchorEl(e.currentTarget)
    // history.push(`${getUserPath(meData.role)}/notification/all`)
  }

  const toggleCalenderModal = () => {
    if (calendarModalOpen) {
      setCalendarModalOpen(false)
    } else {
      setCalendarModalOpen(true)
    }
  }

  // let userSpentLessThan2Days
  // if (meData.createdAt) {
  //   const totalHours = dateAndTimeHelper.getDateDiffInHours(
  //     new Date(meData.createdAt),
  //     new Date()
  //   )
  //   userSpentLessThan2Days = totalHours < 48
  // }

  const isPrivate = [
    'robot.clientvenue.com',
    'localhost:3000',
    'roni.clientvenue.com'
  ].includes(window.location.host)

  return (
    <>
      {/* {!onlineStatus ? (
          <div
            className={clsx(classes.errorMessageContainer, 'offlineMessage')}
          >
            You are offline. Trying to reconnect...
          </div>
        ) : pusherMessage ? (
          <div
            onClick={handleRefresh}
            className={classes.errorMessageContainer}
          >
            {pusherMessage}
            <span className="refreshBtn">
              <RefreshIcon fontSize="small" className="dark:text-dark-light" />
            </span>
          </div>
        ) : null} */}

      <div className="w-full h-full flex items-center justify-between mr-2 sm:mr-0 pl-6">
        <div className="flex items-center mr-4 mobile:mr-2">
          <HeaderTopLeft />
        </div>

        <div className="flex items-center space-x-4">
          {isPrivate &&
            !isMarketplaceDomain &&
            meData.role === userRoles.USER_AGENCY && (
              <MarketplaceButton meData={meData} />
            )}

          {isNewUser && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleCalendlyClick}
              className="flex-shrink-0"
              startIcon={
                <CallOutgoingIcon className="h-3 w-3 lg:h-4 lg:w-4 text-primary-main" />
              }
            >
              Book Demo
            </Button>
          )}

          {meData.role < userRoles.USER_CLIENT && (
            <Button
              size="small"
              variant="outlined"
              onClick={toggleCalenderModal}
              className="flex-shrink-0"
              startIcon={<CalenderIcon className="h-4 w-4" />}
            >
              {t('CALENDAR')}
            </Button>
          )}

          {!timerData.float && Boolean(timerData.activeTimer) && (
            <HeaderTimer />
          )}

          <ChatButton t={t} />

          <Badge badgeContent={unreadNotificationsCount} color="error">
            <button onClick={handleViewNotifications}>
              <Bell className="w-6 h-6 text-primary-main dark:text-dark-light" />
            </button>
          </Badge>

          {/* Only show this button if trial_end is true */}
          {Boolean(meData.payment?.trial_end) && (
            <Button
              onClick={goToSubscriptions}
              size="small"
              className="flex-shrink-0"
              startIcon={
                <TrendingUpOutlinedIcon
                  fontSize="small"
                  className="dark:text-dark-light"
                />
              }
            >
              {t('UPGRADE')}
            </Button>
          )}
          {/*
          Here i'm sending user data instead of me data bcoz when user updates his profile picture
          user data gets updated and not the me data (meData vs userData issue)
          */}
          <HeaderProfileMenu
            timerData={timerData}
            // userSpentLessThan2Days={userSpentLessThan2Days}
            toggleLogModal={toggleLogModal}
            toggleTimerModal={toggleTimerModal}
            toggleStandupsModal={toggleStandupsModal}
            meData={userData}
          />

          {/* ----- Right spacing ----- */}
          <div className="w-2 h-2 flex-shrink-0" />
        </div>
      </div>
      {meData.role < userRoles.USER_CLIENT && (
        <>
          {timerModalOpen && (
            <StartTimerModal open={timerModalOpen} onClose={toggleTimerModal} />
          )}
          {logModalOpen && (
            <LogTimerModal open={logModalOpen} onClose={toggleLogModal} />
          )}
        </>
      )}
      {standupsModalOpen && (
        <CreateStandupsModal
          open={standupsModalOpen}
          onClose={toggleStandupsModal}
        />
      )}
      <AllTasksCalendar
        open={calendarModalOpen}
        onClose={toggleCalenderModal}
      />
      {/* {calendarModalOpen && (
        <CalendarModal
          open={calendarModalOpen}
          handleClose={toggleCalenderModal}
          // elmFor="personal"
        />
      )} */}
      <NotificationPopper
        anchorEl={notifAnchorEl}
        onClose={() => setNotifAnchorEl(null)}
      />
    </>
  )
}

const HeaderTopLeft = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  if (
    pathname.includes(`${path}/reports`) ||
    pathname.includes(`${path}/chat-stream`)
  )
    return null

  return (
    <div style={{ minWidth: 130 }}>
      {pathname.includes(`${path}/settings`) ? (
        <BreadCrumbs />
      ) : pathname.includes(`${path}/dashboard`) ? (
        <AdvanceGlobalSearch />
      ) : (
        <GlobalSearch />
      )}
    </div>
  )
}

const GlobalSearch = () => {
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    // Debounce
    let timer = setTimeout(() => {
      dispatch(setHeaderSearch(search.trim()))
    }, 300)

    return () => clearTimeout(timer)
  }, [search])

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }
  const { t } = useTranslation()

  return (
    <div className="bg-gray-100 border dark:bg-dark-main3 rounded-full 2xl:w-72 flex items-center px-2">
      <SearchIcon className="text-primary-main flex-shrink-0 dark:text-dark-light" />
      <input
        type="text"
        placeholder={t('SEARCH_HERE')}
        className="bg-transparent px-2 py-1.5 w-full text-smaller 2xl:text-sm dark:text-white"
        value={search}
        onChange={handleSearchChange}
      />
      {search && (
        <CancelRoundedIcon
          fontSize="small"
          style={{ fill: 'gray' }}
          onClick={() => setSearch('')}
          className="cursor-pointer dark:text-dark-light"
        />
      )}
    </div>
  )
}

const MarketplaceButton = ({ meData }) => {
  const [marketplaceUserData, setMarketplaceUserData] = useState({
    loading: true,
    data: null
  })

  useEffect(() => {
    fetchUserById(
      { userId: meData._id, params: { role: 'client' } },
      (res, err) => {
        setMarketplaceUserData({ loading: false, data: err ? null : res })
      }
    )
  }, [meData._id])

  const handleRedirectUser = () => {
    const domain = getMarketplaceDomain()
    let redirectUrl = ''

    if (!marketplaceUserData.data) {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify({
          name: meData.name,
          email: meData.email,
          phoneNumber: meData.profile?.phoneNumber,
          language: meData.profile?.language
        }),
        process.env.REACT_APP_PASSWORD_SECRET
      ).toString()

      const queryParams = `?user_ref=${encodeURIComponent(encryptedData)}`
      redirectUrl = `https://${domain}/service-marketplace${queryParams}`
    } else {
      redirectUrl = `https://${domain}`
    }

    const anchor = document.createElement('a')
    anchor.href = redirectUrl
    anchor.rel = 'noopener noreferrer'
    anchor.target = '_blank'

    anchor.click()
  }

  if (marketplaceUserData.loading) return null
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={handleRedirectUser}
      className="flex-shrink-0"
      startIcon={<BriefcaseIcon className="h-3 w-3 2xl:h-4 2xl:w-4" />}
    >
      Service Marketplace
    </Button>
  )
}

const ChatButton = ({ t }) => {
  const dispatch = useDispatch()
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )

  const totalUnreadCount = useMemo(() => {
    let total = 0
    Object.values(unreadMessages)
      .concat(Object.values(personalUnreadMessages))
      .forEach(group => (total += group.unreadCount))

    return total
  }, [unreadMessages, personalUnreadMessages])

  const handleViewChat = () => {
    dispatch(toggleChatModal(true))
  }

  return (
    <Badge badgeContent={totalUnreadCount} color="error">
      <Button
        variant="outlined"
        onClick={handleViewChat}
        startIcon={<ChatIcon />}
        size="small"
      >
        {t('CV_CHAT_NAME')}
      </Button>
    </Badge>
  )
}

export default React.memo(GlobalHeader)
