import React, { useState } from 'react'
import { CustomTooltip } from 'global/globalComponents'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from 'static/svg/info.svg'

const Analytics = ({ campaignData }) => {
  const { t } = useTranslation()
  const [analyticsData, setAnalyticsData] = useState([
    {
      heading: 'Sequence Started',
      value: 0,
      into: t('ANALYTICS_SEQUENCE_INFO')
    },
    {
      heading: 'Opened',
      value: campaignData.totalOpens
        ? `${(campaignData.totalOpens / campaignData.totalSent) * 100}%`
        : '0%',
      info: t('ANALYTICS_OPENED_INFO')
    },
    {
      heading: 'Click Rate',
      value: campaignData.totalClicks
        ? `${(campaignData.totalClicks / campaignData.totalSent) * 100}%`
        : '0%',
      info: t('ANALYTICS_CLICK_RATE_INFO')
    },
    {
      heading: 'Reply Rate',
      value: '0%',
      info: t('ANALYTICS_REPLY_RATE_INFO')
    },
    {
      heading: 'Bounced',
      value: 0,
      info: t('ANALYTICS_BOUNCED_INFO')
    },
    {
      heading: 'Unsubscribed',
      value: campaignData.unsubscribes,
      info: t('ANALYTICS_UNSUBSCRIBED_INFO')
    },
    {
      heading: 'Total email sent',
      value: campaignData.totalSent
    },
    {
      heading: 'Total unique open',
      value: campaignData.uniqueOpens
    },
    {
      heading: 'Total open',
      value: campaignData.totalOpens
    },
    {
      heading: 'Total unique clicks',
      value: campaignData.uniqueClicks
    },
    {
      heading: 'Total clicks',
      value: campaignData.totalClicks
    },
    {
      heading: 'Total bounced',
      value: 0
    }
  ])

  console.log({ campaignData })

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gridGap: '1rem 2rem',
        gap: '2rem 1rem'
      }}
    >
      {analyticsData.map(item => (
        <AnalyticsCard
          key={item.heading}
          heading={item.heading}
          value={item.value}
          info={item.info}
        />
      ))}
    </div>
  )
}

const AnalyticsCard = ({ heading, value, info }) => {
  return (
    <div className="bg-white text-center border rounded-lg p-4">
      <div className="flex items-center mb-4 justify-center">
        <div className="font-bold mr-2">{heading}</div>
        {info && (
          <CustomTooltip title={info} placement="top">
            <InfoIcon className="text-gray-600" />
          </CustomTooltip>
        )}
      </div>
      <div className="font-bold text-3xl">{value}</div>
    </div>
  )
}

export default Analytics
