import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Fetches compaign related contacts
 * @param {Function} callback function (optional)
 */
export const fetchAllContacts = catchAsync(async callback => {
  const res = await axios({
    url: '/contact/user',
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Creates compaign related contact
 * @param {Object} data.body
 * @param {Function} callback function (optional)
 */
export const createContact = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/contact',
    method: 'POST',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Deletes contact by id
 * @param {Object} data.contactId
 * @param {Function} callback function (optional)
 */
export const deleteContact = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/contact/${data.contactId}`,
    method: 'DELETE'
  })

  if (callback) callback(res.data)
})

/**
 * Updates contact by id
 * @param {Object} data.contactId
 * @param {Function} callback function (optional)
 */
export const updateContactById = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/contact/${data.contactId}`,
    method: 'PUT',
    data: data.body
  })

  if (callback) callback(res.data)
})

// Keeping the lists api in the same file, will create a new folder if needed

/**
 * Fetches all campaign lists
 * @param {Function} callback function (optional)
 */
export const fetchAllContactsLists = catchAsync(async callback => {
  const res = await axios({
    url: '/campaignList/user',
    method: 'GET'
  })

  if (callback) callback(res.data)
})

/**
 * Creates list for the campaign contacts
 * @param {Object} data.body
 * @param {Function} callback function (optional)
 */
export const createContactsList = catchAsync(async (data, callback) => {
  const res = await axios({
    url: '/campaignList',
    method: 'POST',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Updates contacts list by id
 * @param {Object} data.listId
 * * @param {Object} data.body update
 * @param {Function} callback function (optional)
 */
export const updateContactsListById = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/campaignList/${data.listId}`,
    method: 'PUT',
    data: data.body
  })

  if (callback) callback(res.data)
})

/**
 * Deletes contacts list by id
 * @param {Object} data.listId
 * @param {Function} callback function (optional)
 */
export const deleteContactsList = catchAsync(async (data, callback) => {
  const res = await axios({
    url: `/campaignList/${data.listId}`,
    method: 'DELETE'
  })

  if (callback) callback(res.data)
})
