import React, { useEffect, useState } from 'react'
import { AlertModal, PopupMenu } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
// import { useSelector } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingsIcon } from 'static/svg/setting.svg'
import { useModal } from 'customHooks'
import { deleteEmailAccount } from 'thunks/emailAcccounts/actions'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

// const totalEmailToDisplay = 8

const EmailAccountsTable = ({
  emailAccountsData,
  isLoading,
  openEmailSettingsPanel,
  deleteEmailAccount
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()

  const handleDeleteEmailAccount = () => {
    toggleLoading()

    deleteEmailAccount({ accountId: deleteAlert.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleLoading()
      } else {
        deleteEmailAccount(deleteAlert.data._id)
        dispatch(fireSuccessToaster('DELETED_SUCCESSFULLY'))
        closeDeleteAlert()
      }
    })
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">{t('EMAIL')}</Th>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="w-20"></Th>
        </Thead>
        <Tbody>
          {isLoading ? (
            <TableDataLoading cols={2} />
          ) : emailAccountsData.length > 0 ? (
            emailAccountsData.map((item, index) => (
              <Row
                t={t}
                key={index}
                emailAccount={item}
                onSettings={() => openEmailSettingsPanel(item)}
                onDelete={() => openDeleteAlert(item)}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                {t('NO_DATA_AVAILABLE')}
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {/* <Pagination
        handlePagination={setPage}
        totalResultsCount={emailAccountsData.length}
        page={page}
        limit={totalEmailToDisplay}
      /> */}

      <AlertModal
        open={deleteAlert.open}
        loading={deleteAlert.loading}
        warningText={t('WARNING_DELETE', {
          data: deleteAlert.data?.email
        })}
        deleteBtnText={'DELETE'}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteEmailAccount}
      />
    </div>
  )
}

const Row = ({ t, emailAccount, onSettings, onDelete }) => {
  return (
    <Tr>
      <Td>{emailAccount.sender.fromEmail}</Td>
      <Td>{emailAccount.sender.fromName}</Td>
      <Td center className="whitespace-nowrap">
        <PopupMenu
          menuItems={[
            {
              label: t('SETTINGS'),
              method: onSettings,
              icon: <SettingsIcon />
            },
            {
              label: t('DELETE'),
              method: onDelete,
              icon: <DeleteIcon />,
              isDanger: true
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default EmailAccountsTable
