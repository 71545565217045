import React, { useMemo } from 'react'
import SidebarLink from './SidebarLink'
import { getSideNavItems } from './sidebarStatic'
import sidebarStyles from './sidebarStyles'
import { useSelector } from 'react-redux'
import userRoles, { getUserPath } from 'utils/userRoles'
import SidebarEmbeds from './SidebarEmbeds'
import { useTranslation } from 'react-i18next'

const SidebarBody = ({ isDrawerOpen }) => {
  const classes = sidebarStyles()
  const { t } = useTranslation()
  const meData = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data?.access)
  const { isMarketplaceDomain } = useSelector(state => state.generalAppState)
  const { unreadMessages, personalUnreadMessages } = useSelector(
    state => state.chats
  )

  const totalUnreadCount = useMemo(() => {
    let total = 0
    Object.values(unreadMessages)
      .concat(Object.values(personalUnreadMessages))
      .forEach(group => (total += group.unreadCount))

    return total
  }, [unreadMessages, personalUnreadMessages])

  const userPath = getUserPath(meData.role)

  const { workManagement, leadsGeneration } = useMemo(() => {
    return getSideNavItems({
      meRole: meData.role,
      permission,
      basePath: userPath,
      isMarketplaceDomain,
      unreadChatCount: totalUnreadCount,
      accountCreatedAt: meData.createdAt
    })
  }, [
    meData.role,
    meData.createdAt,
    isMarketplaceDomain,
    permission,
    totalUnreadCount,
    userPath
  ])

  const canManageLeads = meData.role === userRoles.USER_AGENCY

  return (
    <div className="my-4 overflow-auto">
      {isDrawerOpen && canManageLeads && (
        <div className="text-smaller font-semibold px-2 capitalize">
          {t('WORK_MANAGEMENT')}
        </div>
      )}

      <div className="mb-4">
        {workManagement.map(item => (
          <SidebarLink
            key={item.text}
            navItem={item}
            path={`${userPath}${item.link}`}
            classes={classes}
            isDrawerOpen={isDrawerOpen}
          />
        ))}
      </div>

      {canManageLeads && (
        <div>
          {isDrawerOpen && (
            <div className="text-smaller font-semibold px-2 capitalize">
              {t('SIDEBAR_LEAD_GENERATION_HEADING')}
            </div>
          )}
          {leadsGeneration.map(item => (
            <SidebarLink
              key={item.text}
              navItem={item}
              path={`${userPath}${item.link}`}
              classes={classes}
              isDrawerOpen={isDrawerOpen}
            />
          ))}
        </div>
      )}

      {meData.role >= userRoles.USER_CLIENT && (
        <SidebarEmbeds
          meId={meData._id}
          classes={classes}
          isDrawerOpen={isDrawerOpen}
        />
      )}
    </div>
  )
}

export default SidebarBody
