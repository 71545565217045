import React, { useEffect, useImperativeHandle, useRef } from 'react'
import Quill from 'quill'
import { makeStyles } from '@material-ui/core'

// Note: In this editor floating toolbar shows up on text select

const EDITOR_CONTAINER = 'editorContainer'

const EmailEditor = React.forwardRef(
  ({ defaultHtml, placeholder, disabled, height, error }, ref) => {
    const editor = useRef()
    const quill = useRef()
    const toolbar = useRef()
    const classes = useStyles()
    const config = {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', { list: 'bullet' }, 'link'] // toggled buttons
        ]
      },
      placeholder: placeholder || 'Type here...',
      bounds: `#${EDITOR_CONTAINER}`
    }

    useImperativeHandle(ref, () => ({
      getEmailBody: () => quill.current.root.innerHTML
    }))

    useEffect(() => {
      attachQuill()
    }, [])

    const attachQuill = () => {
      quill.current = new Quill(editor?.current, config)

      if (disabled) {
        quill.current.enable(false)
      }

      toolbar.current = quill.current.getModule('toolbar')
      toolbar.current.container.style.visibility = 'hidden'
      toolbar.current.container.style.position = 'absolute'

      quill.current.on('selection-change', onSelectionChange)
      // quill.current.on('text-change', () => {
      //   onChange(quill.current.root.innerHTML)
      // })
      setDefaultContent()
    }

    const setDefaultContent = () => {
      const quote = quill.current.clipboard.convert(defaultHtml || '<p> </p>')
      quill.current.setContents(quote, Quill.sources.SILENT)
    }

    const onSelectionChange = range => {
      const toolbarElement = toolbar.current.container

      if (range) {
        if (range.length === 0) {
          hideInactiveToolbar()
        } else {
          const { top, left } = quill.current.getBounds(range)
          const shift = 49
          toolbarElement.style.visibility = 'visible'
          toolbarElement.style.top = `${top - shift}px` // TODO need additional calculation for edge cases
          toolbarElement.style.left = `${left}px`
        }
      } else {
        hideInactiveToolbar()
      }
    }

    const hideInactiveToolbar = () => {
      const element = toolbar.current?.container
      const isToolbarFocused =
        element &&
        (element.contains(document.activeElement) ||
          element === document.activeElement)
      if (!isToolbarFocused) {
        element.style.visibility = 'hidden'
      }
    }

    return (
      <div className={classes.editor} id={EDITOR_CONTAINER}>
        <div
          ref={editor}
          style={{
            height: height || 100,
            border: 'none'
            // border: `1px solid ${error ? 'red' : '#ccc'}`,
            // borderRadius: 5
          }}
        />
      </div>
    )
  }
)

const useStyles = makeStyles(theme => ({
  editor: {
    border: 'none',
    position: 'relative',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.custom.darkMode.dark2 : '',
    color: theme.palette.type === 'dark' ? '#fff' : '',
    '& .ql-toolbar.ql-snow': {
      backgroundColor: '#fff',
      zIndex: 99,
      whiteSpace: 'nowrap'
      // width: 175
    },
    '& *': {
      fontSize: 15
    }
  }
}))

export default EmailEditor
