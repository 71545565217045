import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import { AlertModal, Pagination, PopupMenu } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { deleteContactsList } from 'thunks/contacts/actions'
import { useModal } from 'customHooks'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

// const totalItemsToDisplay = 8

const ListTable = ({
  contactsListsData,
  isLoading,
  removeContactsList,
  openEditPanel
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const search = useSelector(state => state.generalAppState.headerSearch)
  // const [page, setPage] = useState(0)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()

  const handleDeleteList = () => {
    toggleLoading()
    deleteContactsList({ listId: deleteAlert.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleLoading()
      } else {
        removeContactsList(deleteAlert.data._id)
        dispatch(fireSuccessToaster(t('SUCCESS')))
        closeDeleteAlert()
      }
    })
  }

  return (
    <div>
      <TableContainer mb={6} id="clientsTable">
        <Thead>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="whitespace-nowrap">{t('CREATED_AT')}</Th>
          <Th className="whitespace-nowrap" center>
            {t('CONTACTS')}
          </Th>
          {/* <Th className="whitespace-nowrap" center></Th> */}
          <Th className="w-20"></Th>
        </Thead>
        <Tbody>
          {isLoading ? (
            <TableDataLoading cols={5} />
          ) : contactsListsData.length > 0 ? (
            contactsListsData.map((item, index) => (
              <Row
                t={t}
                key={index}
                list={item}
                onDelete={() => openDeleteAlert(item)}
                onEdit={() => openEditPanel(item)}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                {t('NO_DATA_AVAILABLE')}
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {/* <Pagination
        handlePagination={setPage}
        totalResultsCount={contactsListsData.length}
        page={page}
        limit={totalItemsToDisplay}
      /> */}
      <AlertModal
        open={deleteAlert.open}
        loading={deleteAlert.loading}
        warningText={t('WARNING_DELETE', {
          data: deleteAlert.data?.name
        })}
        deleteBtnText={'DELETE'}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteList}
      />
    </div>
  )
}

const Row = ({ t, list, onDelete, onEdit }) => {
  return (
    <Tr>
      <Td>{list.name}</Td>
      <Td>
        {new Date(list.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })}
      </Td>
      <Td center>{list.contactCount || 0}</Td>
      {/* <Td center>
        <Button variant="outlined" size="large" className="whitespace-nowrap">
          {t('SEND')} {t('CAMPAIGN')}
        </Button>
      </Td> */}
      <Td center className="whitespace-nowrap">
        <PopupMenu
          menuItems={[
            {
              label: t('EDIT'),
              method: onEdit,
              icon: <PenIcon />
            },
            {
              label: t('DELETE'),
              method: onDelete,
              icon: <DeleteIcon />,
              isDanger: true
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default ListTable
