import React, { useEffect, useRef, useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { CustomTabs, LoadingButton } from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
import { useHistory } from 'react-router-dom'
import CampaignConfig from './CampaignConfig'
import { updateCampaignConfig } from 'thunks/Campaign/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import Analytics from './Analytics'
import EmailSequences from './EmailSequences'
import { fetchAllEmailAccounts } from 'thunks/emailAcccounts/actions'
import { fetchAllContactsLists } from 'thunks/contacts/actions'
import Leads from './Leads'

const CampaignDetails = ({ campaignsData }) => {
  const { campaignId } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(0)
  const [campaignData, setCampaignData] = useState({
    campaignName: '',
    config: {
      options: {
        bcc: [],
        cc: [],
        clickTracking: false,
        dailyLimit: '',
        emailList: [],
        days: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false
        },
        startDate: '',
        endDate: '',
        infinitelyRunning: false,
        linkTracking: false,
        maxNewLeads: '',
        openTracking: false,
        prioritizeFollowup: false,
        providerMatching: false,
        randomAdditionalTimeGap: '',
        stopOnCompanyReply: false,
        stopOnReply: false,
        textOnly: false,
        timeGap: '',
        timezone: '',
        timing: {
          from: '',
          to: ''
        }
      },
      sequences: {
        steps: []
      },
      leads: []
    }
  })
  // const campaign = campaignsData.find(item => item._id === campaignId)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [emailAccountsData, setEmailAccountsData] = useState({
    loading: true,
    data: []
  })
  const [contactsLists, setContactsLists] = useState({
    loading: true,
    data: []
  })

  useEffect(() => {
    fetchAllEmailAccounts((res, err) => {
      setEmailAccountsData({ loading: false, data: err ? [] : res })
    })

    fetchAllContactsLists((res, err) => {
      setContactsLists({ loading: false, data: err ? [] : res })
    })
  }, [])
  const [screenLoading, setScreenLoading] = useState(true)

  const tabsData = [
    {
      label: t('ANALYTICS'),
      value: 0
    },
    {
      label: t('LEADS'),
      value: 1
    },
    {
      label: t('SEQUENCES'),
      value: 2
    },
    {
      label: t('SCHEDULE'),
      value: 3
    }
  ]

  useEffect(() => {
    const fetchedCampaignData = campaignsData.find(
      item => item._id === campaignId
    )

    if (fetchedCampaignData) {
      setCampaignData(prev => ({
        ...fetchedCampaignData,
        config: {
          options: fetchedCampaignData.config.options || prev.config.options,
          sequences:
            fetchedCampaignData.config.sequences || prev.config.sequences,
          leads: fetchedCampaignData.config.leads || []
        }
      }))
      setScreenLoading(false)
    }
  }, [campaignsData])

  const handleSaveChanges = () => {
    // ======= Validation ========
    // start & end date
    if (
      new Date(campaignData.config.options.endDate) <
      new Date(campaignData.config.options.startDate)
    ) {
      dispatch(fireErrorToaster('End date must be greater than start date!'))
      return
    }

    // From & to time
    const minEndTime = getMinEndTime(campaignData.config.options.timing.from)
    if (campaignData.config.options.timing.to < minEndTime) {
      dispatch(fireErrorToaster(t('FROM_TO_GAP_ERROR')))
      return
    }

    console.log({ campaignData })
    // return

    setLoading(true)
    updateCampaignConfig(
      {
        campaignId,
        body: campaignData
      },
      (res, err) => {
        setLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
        }
      }
    )
  }

  const handleUpdateOptionsField = ({ fieldName, value }) => {
    setCampaignData(prev => ({
      ...prev,
      config: {
        ...prev.config,
        options: {
          ...prev.config.options,
          [fieldName]: value
        }
      }
    }))
  }

  const handleLeadsSelection = listId => {
    setCampaignData(prev => ({
      ...prev,
      config: {
        ...prev.config,
        leads: prev.config.leads.includes(listId)
          ? prev.config.leads.filter(id => id !== listId)
          : [...prev.config.leads, listId]
      }
    }))
  }

  const handleReplaceCampaignData = campaignData => {
    setCampaignData(campaignData)
  }

  if (screenLoading) return <LoadingAnimation />

  return (
    <OuterContentWrapper>
      <div className="mb-6 2xl:mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={() => history.push('/agency/campaigns')}
            className="border border-gray-300 rounded rotate-180 px-2 py-2 hover:bg-gray-100"
          >
            <ArrowRight />
          </button>
          <SectionHeading className="ml-4">
            {campaignData.campaignName}
          </SectionHeading>
        </div>

        {/* Hide this save button when sequences tab is active */}
        {selectedTab !== 2 && (
          <div className="flex items-center">
            <div className="text-gray-400 mr-4 capitalize text-sm">
              ({campaignData.status})
            </div>
            <LoadingButton
              loading={loading}
              size="large"
              onClick={handleSaveChanges}
            >
              {t('SAVE')}
            </LoadingButton>
          </div>
        )}
      </div>
      <div className="mb-6">
        <CustomTabs
          tabsData={tabsData}
          value={selectedTab}
          onChange={setSelectedTab}
        />
      </div>

      {selectedTab === 0 ? (
        <Analytics campaignData={campaignData} />
      ) : selectedTab === 1 ? (
        <Leads
          selectedLeads={campaignData.config.leads}
          contactsLists={contactsLists}
          handleLeadsSelection={handleLeadsSelection}
        />
      ) : selectedTab === 2 ? (
        <EmailSequences
          campaignData={campaignData}
          handleReplaceCampaignData={handleReplaceCampaignData}
          handleSaveCampaignData={handleSaveChanges}
        />
      ) : selectedTab === 3 ? (
        <CampaignConfig
          campaignConfigOptions={campaignData.config.options}
          handleUpdateOptionsField={handleUpdateOptionsField}
          emailAccountsData={emailAccountsData.data}
        />
      ) : null}
    </OuterContentWrapper>
  )
}

const getMinEndTime = start => {
  if (!start) return ''
  const startDate = new Date(`1970-01-01T${start}:00`)
  startDate.setHours(startDate.getHours() + 2) // Add 2 hours to start time
  return startDate.toTimeString().slice(0, 5) // Format as HH:MM
}

const LoadingAnimation = () => {
  return (
    <OuterContentWrapper>
      <div className="mb-4">
        <Skeleton
          variant="rect"
          width="25%"
          height={40}
          className="mb-4 rounded"
        />
        <Skeleton variant="rect" width="100%" height={60} className="rounded" />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gridGap: '1rem 2rem',
          gap: '2rem 1rem'
        }}
      >
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
        <Skeleton variant="rect" height={150} className="rounded" />
      </div>
    </OuterContentWrapper>
  )
}

export default CampaignDetails
