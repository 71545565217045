import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, TextField, useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  CheckBoxField,
  FormLabel,
  LoadingButton
} from 'global/globalComponents'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import { addEmailAccount } from 'thunks/emailAcccounts/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const AddEmailAccountPanel = ({ open, onClose, data, addNewEmailAccount }) => {
  const { t } = useTranslation()

  return (
    <CustomDrawer onClose={onClose} anchor="right" open={open}>
      <DrawerHeader onClose={onClose} className="capitalize">
        {`${t('ADD')} ${t('EMAIL_ACCOUNT')}`}
      </DrawerHeader>

      <PanelContent
        onClose={onClose}
        data={data}
        t={t}
        addNewEmailAccount={addNewEmailAccount}
      />
    </CustomDrawer>
    // <CustomModal open={open} handleClose={onClose} size="large">
    //   <CustomModalHeader
    //     heading={`${t('ADD')} ${t('EMAIL_ACCOUNT')}`}
    //     handleClose={onClose}
    //   />
    //   <ModalContent t={t} onClose={onClose} />
    // </CustomModal>
  )
}

const PanelContent = ({ t, onClose, addNewEmailAccount }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    type: 'any',
    sender: {
      fromEmail: '',
      fromName: '',
      replyToEmail: '',
      replyToName: ''
    },

    credentials: {
      secure: true,
      host: '',
      port: 993,
      username: '',
      password: ''
    }
  })

  const handleAddEmailAccount = () => {
    setLoading(true)
    addEmailAccount({ body: formData }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        setLoading(false)
      } else {
        dispatch(fireSuccessToaster(t('ADDED_SUCCESSFULLY')))
        addNewEmailAccount(res)
        onClose()
      }
    })
  }

  const handleSenderDataChange = e => {
    const { name, value } = e.target

    console.log({ name, value })
    setFormData(prev => ({
      ...prev,
      sender: {
        ...prev.sender,
        [name]: value
      }
    }))
  }

  const handleCredentialsDataChange = e => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      credentials: {
        ...prev.credentials,
        [name]: name === 'port' ? Number(value) : value
      }
    }))
  }

  const toggleSecurity = e => {
    setFormData(prev => ({
      ...prev,
      credentials: {
        ...prev.credentials,
        secure: e.target.checked,
        port: e.target.checked ? 993 : 143
      }
    }))
  }

  return (
    <>
      <DrawerContent open={true}>
        <div className="mb-4">
          <FormLabel required>
            {t('FROM')} {t('NAME')}
          </FormLabel>
          <TextField
            required
            fullWidth
            name="fromName"
            // placeholder={`${t('FROM')} ${t('NAME')}`}
            onChange={handleSenderDataChange}
            value={formData.sender.fromName}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-4">
          <FormLabel required>
            {t('FROM')} {t('EMAIL')}
          </FormLabel>
          <TextField
            required
            fullWidth
            name="fromEmail"
            // placeholder={`${t('FROM')} ${t('EMAIL')}`}
            onChange={handleSenderDataChange}
            value={formData.sender.fromEmail}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-4">
          <FormLabel>
            {t('REPLY_TO')} {t('NAME')}
          </FormLabel>
          <TextField
            required
            fullWidth
            name="replyToName"
            // placeholder={`${t('REPLY_TO')} ${t('NAME')}`}
            onChange={handleSenderDataChange}
            value={formData.sender.replyToName}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-4">
          <FormLabel>
            {t('REPLY_TO')} {t('EMAIL')}
          </FormLabel>
          <TextField
            required
            fullWidth
            name="replyToEmail"
            // placeholder={`${t('REPLY_TO')} ${t('EMAIL')}`}
            onChange={handleSenderDataChange}
            value={formData.sender.replyToEmail}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        {/* <div className="mb-4">
          <FormLabel required>{t('SERVICE')}</FormLabel>
          <Select
            value={SMTPServices.find(item => item.value === formData.service)}
            options={SMTPServices}
            onChange={handleServiceChange}
            styles={reactSelectCustomStyles(theme)}
          />
        </div> */}

        <div className="mb-4">
          <FormLabel htmlFor="host" required>
            {t('HOST')}
          </FormLabel>
          <TextField
            id="host"
            className="w-full"
            name="host"
            // placeholder={t('HOST')}
            onChange={handleCredentialsDataChange}
            value={formData.credentials.host}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="port">{t('PORT')}</FormLabel>
          <TextField
            type="number"
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
            id="port"
            className="w-full"
            name="port"
            // placeholder={t('PORT')}
            onChange={handleCredentialsDataChange}
            value={formData.credentials.port}
          />
        </div>

        <div className="mb-4">
          <FormLabel htmlFor="username" required>
            {t('USERNAME')}
          </FormLabel>
          <TextField
            required
            name="username"
            className="w-full"
            onChange={handleCredentialsDataChange}
            value={formData.credentials.username}
            placeholder={t('USERNAME')}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-3">
          <FormLabel htmlFor="password" required>
            {t('PASSWORD')}
          </FormLabel>
          <TextField
            autoComplete="new-password"
            required
            type="password"
            name="password"
            className="w-full"
            onChange={handleCredentialsDataChange}
            value={formData.password}
            style={{
              background:
                theme.palette.type !== 'light'
                  ? theme.custom.darkMode.background
                  : ''
            }}
          />
        </div>

        <div className="mb-6">
          <CheckBoxField
            label={t('SECURE')}
            checked={formData.credentials.secure}
            onChange={toggleSecurity}
          />
        </div>
      </DrawerContent>
      <DrawerFooter>
        <Button
          size="large"
          variant="outlined"
          className="w-full"
          onClick={onClose}
        >
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={handleAddEmailAccount}
          loading={loading}
          className="w-full"
        >
          {t('ADD')}
        </LoadingButton>
      </DrawerFooter>
    </>
  )
}

// const getSMTPServiceRelatedData = service => {
//   if (!service) return {}

//   const data = {
//     other: { host: '', port: '', encryption: '' },
//     gmail: { host: 'smtp.gmail.com', port: 465, encryption: 'SSL' },
//     hotmailoutlook: {
//       host: 'smtp.office365.com',
//       port: 587,
//       encryption: 'TLS'
//     },
//     sendinblue: {
//       host: 'smtp-relay.sendinblue.com',
//       port: 587,
//       encryption: 'TLS'
//     },
//     sendgrid: { host: 'smtp.sendgrid.net', port: 587, encryption: 'SSL' },
//     mailgun: { host: 'smtp.mailgun.org', port: 587, encryption: 'TLS' }
//   }

//   return data[service.toLowerCase()]
// }

// const SMTPServices = [
//   {
//     label: 'Other',
//     value: 'other',
//     host: '',
//     port: '',
//     encryption: ''
//   },
//   {
//     label: 'Gmail',
//     value: 'gmail',
//     host: 'smtp.gmail.com',
//     port: 465,
//     encryption: 'SSL'
//   },
//   {
//     label: 'Hotmail/Outlook',
//     value: 'hotmailoutlook',
//     host: 'smtp.office365.com',
//     port: 587,
//     encryption: 'TLS'
//   },
//   {
//     label: 'SendInBlue',
//     value: 'sendinblue',
//     host: 'smtp-relay.sendinblue.com',
//     port: 587,
//     encryption: 'TLS'
//   },
//   {
//     label: 'SendGrid',
//     value: 'sendgrid',
//     host: 'smtp.sendgrid.net',
//     port: 465,
//     encryption: 'SSL'
//   },
//   {
//     label: 'MailGun',
//     value: 'mailgun',
//     host: 'smtp.mailgun.org',
//     port: 587,
//     encryption: 'TLS'
//   }
// ]

// const cryptoList = [
//   { label: 'SSL', value: 'ssl' },
//   { label: 'TLS', value: 'tls' },
//   { label: 'None', value: 'none' }
// ]

export default AddEmailAccountPanel
