import React, { useState } from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import CampaignsTable from './CampaignsTable'
import CreateCampaignModal from './CreateCampaignModal'

const AllCampaignsPage = ({ campaigns, setCampaigns }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const addNewCampaign = campaign => {
    setCampaigns(prev => ({ ...prev, data: [campaign, ...prev.data] }))
  }

  const removeCampaign = campaignId => {
    setCampaigns(prev => ({
      ...prev,
      data: prev.data.filter(item => item._id !== campaignId)
    }))
  }

  const updateCampaignData = campaign => {
    setCampaigns(prev => ({
      ...prev,
      data: prev.data.map(item => (item._id === campaign._id ? campaign : item))
    }))
  }

  return (
    <OuterContentWrapper>
      <div className="mb-6 2xl:mb-8 flex justify-between items-center">
        <SectionHeading>{t('SMART_CAMPAIGN')}</SectionHeading>

        <div>
          <Button startIcon={<AddIcon />} onClick={() => setModalOpen(true)}>
            {t('CREATE')} {t('CAMPAIGN')}
          </Button>
        </div>
      </div>

      <CampaignsTable
        isLoading={campaigns.loading}
        campaignsData={campaigns.data}
        removeCampaign={removeCampaign}
      />

      <CreateCampaignModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        addNewCampaign={addNewCampaign}
      />
    </OuterContentWrapper>
  )
}

export default AllCampaignsPage
