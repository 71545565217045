import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EnvelopeIcon } from 'static/svg/envelope.svg'
import { ReactComponent as SunIcon } from 'static/svg/sun.svg'
import { ReactComponent as ClockIcon } from 'static/svg/clock.svg'
import { ReactComponent as PinIcon } from 'static/svg/pin.svg'
import { ReactComponent as BulbIcon } from 'static/svg/bulb.svg'
import { ReactComponent as SendIcon } from 'static/svg/send.svg'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  Checkbox,
  FormControlLabel,
  TextField,
  useTheme
} from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank
} from '@material-ui/icons'
import clsx from 'clsx'
import ct from 'countries-and-timezones'
import { FormLabel } from 'global/globalComponents'

const CampaignConfig = ({
  campaignConfigOptions,
  emailAccountsData,
  handleUpdateOptionsField
}) => {
  const { t } = useTranslation()
  const muiTheme = useTheme()
  const emailOptions = emailAccountsData.map(item => ({
    label: item.sender.fromEmail,
    value: item._id
  }))
  const selectedEmailAccounts = campaignConfigOptions.emailList.map(
    emailAccountId => {
      const match = emailAccountsData.find(item => item._id === emailAccountId)

      return {
        label: match?.sender?.fromEmail,
        value: match?._id
      }
    }
  )

  const timezoneOptions = useMemo(() => {
    const countries = ct.getAllCountries()
    let arr = []
    Object.values(countries).forEach(country => {
      arr = arr.concat(country.timezones)
    })

    return arr.sort().map(el => (el = { value: el, label: el }))
  }, [])

  const weekdays = [
    t('SUNDAY'),
    t('MONDAY'),
    t('TUESDAY'),
    t('WEDNESDAY'),
    t('THURSDAY'),
    t('FRIDAY'),
    t('SATURDAY')
  ]

  const handleShowPicker = e => {
    if (e.target.showPicker) {
      e.target.showPicker()
    }
  }

  // const handleChangeSelectedDays = (isChecked, dayIndex) => {
  //   setCampaignConfig(prev => ({
  //     ...prev,
  //     days: { ...prev.days, [dayIndex]: isChecked }
  //   }))
  // }

  const handleDateChange = e => {
    const date = new Date(e.target.value)

    // setting time
    if (e.target.name === 'startDate') date.setHours(0, 0, 0)
    else date.setHours(23, 59, 59)

    handleUpdateOptionsField({
      fieldName: e.target.name,
      value: date.toISOString()
    })
  }

  const handleTextValueChange = e => {
    handleUpdateOptionsField({
      fieldName: e.target.name,
      value: e.target.value
    })
  }

  const handleCheckboxValueChange = e => {
    handleUpdateOptionsField({
      fieldName: e.target.name,
      value: e.target.checked
    })
  }

  return (
    <div>
      {/* Email to use card */}
      <Card className="mb-8" heading={t('EMAILS_TO_USE')} Icon={EnvelopeIcon}>
        <p className="text-smaller text-gray-500 mb-2">
          {t('EMAILS_TO_USE_DESCRIPTION')}
        </p>

        <Select
          value={selectedEmailAccounts}
          isMulti
          isSearchable={true}
          options={emailOptions}
          onChange={value =>
            handleUpdateOptionsField({
              fieldName: 'emailList',
              value: value.map(item => item.value)
            })
          }
          styles={reactSelectCustomStyles(muiTheme)}
          className="w-full"
        />
      </Card>

      {/* ======= Schedule ====== */}
      <div className="mb-8">
        <div className="font-semibold mb-4">{t('SCHEDULE')}</div>
        <div className="flex">
          {/* Days card */}
          <Card className="flex-1 mr-6" heading={t('DAYS')} Icon={SunIcon}>
            <div className="mb-4">
              {weekdays.map((day, i) => (
                <CheckBoxField
                  label={day}
                  checked={campaignConfigOptions.days[i]}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'days',
                      value: {
                        ...campaignConfigOptions.days,
                        [i]: e.target.checked
                      }
                    })
                  }
                />
              ))}
            </div>

            <div className="flex mb-4">
              <div className="flex-1 mr-4">
                <label className="font-medium text-smaller block mb-1">
                  {t('START_DATE')}
                </label>

                <TextField
                  type="date"
                  name="startDate"
                  value={
                    campaignConfigOptions.startDate
                      ? campaignConfigOptions.startDate.split('T')[0]
                      : undefined
                  }
                  onClick={handleShowPicker}
                  onChange={handleDateChange}
                  fullWidth
                />
              </div>

              <div className="flex-1">
                <label className="font-medium text-smaller block mb-1">
                  {t('END_DATE')}
                </label>
                <TextField
                  name="endDate"
                  type="date"
                  inputProps={{
                    min: campaignConfigOptions.startDate
                      ? campaignConfigOptions.startDate.split('T')[0]
                      : ''
                  }}
                  value={
                    campaignConfigOptions.endDate
                      ? campaignConfigOptions.endDate.split('T')[0]
                      : ''
                  }
                  fullWidth
                  onClick={handleShowPicker}
                  onChange={handleDateChange}
                />
              </div>
            </div>

            <div>
              <CheckBoxField
                checked={campaignConfigOptions.infinitelyRunning}
                onChange={e =>
                  handleUpdateOptionsField({
                    fieldName: 'infinitelyRunning',
                    value: e.target.checked
                  })
                }
                label={t('RUN_CAMPAIGN_INFINITELY')}
              />
            </div>
          </Card>

          {/* ----- Timing card ----- */}
          <Card className="flex-1" heading={t('TIMING')} Icon={ClockIcon}>
            <div className="flex mb-6">
              <div className="flex-1 mr-4">
                <label className="font-medium text-smaller block mb-1">
                  {t('FROM')}
                </label>

                <TextField
                  type="time"
                  value={campaignConfigOptions.timing.from}
                  fullWidth
                  onClick={handleShowPicker}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'timing',
                      value: {
                        ...campaignConfigOptions.timing,
                        from: e.target.value
                      }
                    })
                  }
                />
              </div>

              <div className="flex-1">
                <label className="font-medium text-smaller block mb-1">
                  {t('TO')}
                </label>
                <TextField
                  type="time"
                  value={campaignConfigOptions.timing.to}
                  fullWidth
                  onClick={handleShowPicker}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'timing',
                      value: {
                        ...campaignConfigOptions.timing,
                        to: e.target.value
                      }
                    })
                  }
                />
              </div>
            </div>

            <div>
              <label className="font-medium text-smaller block mb-1">
                {t('TIMEZONE')}
              </label>
              <Select
                isSearchable={true}
                placeholder={t('TIMEZONE')}
                onChange={option =>
                  handleUpdateOptionsField({
                    fieldName: 'timezone',
                    value: option.value
                  })
                }
                maxMenuHeight={350}
                value={timezoneOptions.find(
                  item =>
                    item.value.toLowerCase() ===
                    campaignConfigOptions.timezone.toLowerCase()
                )}
                options={timezoneOptions}
                styles={reactSelectCustomStyles(muiTheme)}
                menuPlacement="top"
              />
            </div>
          </Card>
        </div>
      </div>

      {/* ======= Options ====== */}
      <div className="mb-8">
        <div className="font-semibold mb-4">{t('OPTIONS')}</div>

        <div className="flex">
          <div className="flex flex-col flex-1 mr-6">
            <Card className="mb-6" heading={t('TRACKING')} Icon={PinIcon}>
              <div>
                <CheckBoxField
                  checked={campaignConfigOptions.openTracking}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'openTracking',
                      value: e.target.checked
                    })
                  }
                  label={t('TRACK_EMAIL_OPENS')}
                />
              </div>
              <div>
                <CheckBoxField
                  checked={campaignConfigOptions.clickTracking}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'clickTracking',
                      value: e.target.checked
                    })
                  }
                  label={t('TRACK_LINK_CLICKS')}
                />
              </div>
              <div>
                <CheckBoxField
                  checked={campaignConfigOptions.stopOnReply}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'stopOnReply',
                      value: e.target.checked
                    })
                  }
                  label={t('STOP_CAMPAIGN_CONTACT_REPLY')}
                />
              </div>
              <div>
                <CheckBoxField
                  checked={campaignConfigOptions.stopOnCompanyReply}
                  onChange={e =>
                    handleUpdateOptionsField({
                      fieldName: 'stopOnCompanyReply',
                      value: e.target.checked
                    })
                  }
                  label={t('STOP_CAMPAIGN_COMPANY_REPLY')}
                  subLabel={t('STOP_CAMPAIGN_COMPANY_REPLY_DESCRIPTION')}
                />
              </div>
            </Card>

            <Card
              className="flex-1"
              heading={t('OPTIMIZATION')}
              Icon={BulbIcon}
            >
              <CheckBoxField
                checked={campaignConfigOptions.textOnly}
                onChange={e =>
                  handleUpdateOptionsField({
                    fieldName: 'textOnly',
                    value: e.target.checked
                  })
                }
                label={t('SEND_EMAILS_AS_TEXT')}
                subLabel={t('SEND_EMAILS_AS_TEXT_DESCRIPTION')}
              />
            </Card>
          </div>

          <div className="flex-1">
            <Card heading={t('SENDING_PATTERN')} Icon={SendIcon}>
              <div className="mb-4">
                <FormLabel>{t('CAMPAIGN_MAX_EMAILS_PER_DAY')}</FormLabel>
                <TextField
                  type="number"
                  name="dailyLimit"
                  value={campaignConfigOptions.dailyLimit}
                  onChange={handleTextValueChange}
                  fullWidth
                />
              </div>

              <div className="mb-4">
                <FormLabel>{t('EMAILS_TIME_GAP')}</FormLabel>
                <TextField
                  type="number"
                  name="timeGap"
                  value={campaignConfigOptions.timeGap}
                  onChange={handleTextValueChange}
                  fullWidth
                />
              </div>

              <div className="mb-4">
                <FormLabel>{t('ADDITIONAL_EMAILS_TIME_GAP')}</FormLabel>
                <TextField
                  type="number"
                  name="randomAdditionalTimeGap"
                  value={campaignConfigOptions.randomAdditionalTimeGap}
                  onChange={handleTextValueChange}
                  fullWidth
                />
              </div>

              <div className="mb-4">
                <FormLabel>{t('MAX_LEADS_PER_DAY')}</FormLabel>
                <TextField
                  type="number"
                  name="maxNewLeads"
                  value={campaignConfigOptions.maxNewLeads}
                  onChange={handleTextValueChange}
                  fullWidth
                />
              </div>

              <div className="mb-4">
                <CheckBoxField
                  checked={!campaignConfigOptions.prioritizeFollowup}
                  name="prioritizeFollowup"
                  onChange={handleCheckboxValueChange}
                  label={t('PRIORITIZE_NEW_LEADS')}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

const CheckBoxField = ({
  name,
  onChange,
  checked,
  label,
  subLabel,
  disabled = false
}) => {
  return (
    <FormControlLabel
      className="flex items-center"
      name={name}
      control={
        <Checkbox
          onChange={onChange}
          checked={checked}
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={
            <CheckBoxIcon fontSize="small" className="dark:text-dark-light" />
          }
          value={checked}
          disabled={disabled}
        />
      }
      label={
        <div>
          <div className="text-smaller font-medium text-gray-700 dark:text-dark-light select-none">
            {label}
          </div>
          {subLabel && (
            <div className="mt-1 text-xs text-gray-500 dark:text-dark-light select-none">
              {subLabel}
            </div>
          )}
        </div>
      }
    />
  )
}

const Card = ({ children, className, heading, Icon }) => {
  return (
    <div
      className={clsx(
        'p-6 rounded-lg bg-white border border-gray-300',
        className
      )}
    >
      <div className="flex items-center mb-6">
        <Icon className="mr-2 text-primary-main" />
        <div className="text-sm font-semibold">{heading}</div>
      </div>

      {children}
    </div>
  )
}

export default CampaignConfig
