import clsx from 'clsx'
import { useModal } from 'customHooks'
import { AlertModal, Pagination, PopupMenu } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { deleteContact } from 'thunks/contacts/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const limit = 8

const CampaignContactsTable = ({
  isLoading,
  contactsData,
  removeContact,
  openEditPanel
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const search = useSelector(state => state.generalAppState.headerSearch)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()
  const [page, setPage] = useState(0)
  const visibleContacts = useMemo(() => {
    let arr = [...contactsData]

    if (search.trim() !== '') {
      arr = arr.filter(
        item =>
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          (item.firstName + ' ' + item.lastName)
            .toLowerCase()
            .includes(search.toLowerCase())
      )
    }

    return arr.splice(page * limit, limit)
  }, [page, search, contactsData])

  const handleDeleteCampaign = () => {
    toggleLoading()
    deleteContact({ contactId: deleteAlert.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleLoading()
      } else {
        removeContact(deleteAlert.data._id)
        dispatch(fireSuccessToaster(t('SUCCESS')))
        closeDeleteAlert()
      }
    })
  }

  return (
    <div>
      <TableContainer mb={6} id="clientsTable">
        <Thead>
          <Th className="whitespace-nowrap">{t('EMAIL')}</Th>
          <Th className="whitespace-nowrap">{t('CREATED_AT')}</Th>
          <Th className="whitespace-nowrap">{t('NAME')}</Th>
          <Th className="whitespace-nowrap">{t('STATUS')}</Th>
          <Th className="whitespace-nowrap" center>
            {t('VERIFIED')}
          </Th>
          <Th className="w-20"></Th>
        </Thead>
        <Tbody>
          {isLoading ? (
            <TableDataLoading cols={6} />
          ) : visibleContacts.length > 0 ? (
            visibleContacts.map((item, index) => (
              <Row
                t={t}
                key={index}
                contact={item}
                onEdit={() => openEditPanel(item)}
                onDelete={() => openDeleteAlert(item)}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                {t('NO_DATA_AVAILABLE')}
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={contactsData.length}
        page={page}
        limit={limit}
      />
      <AlertModal
        open={deleteAlert.open}
        loading={deleteAlert.loading}
        warningText={t('WARNING_DELETE', {
          data: deleteAlert.data?.email
        })}
        deleteBtnText={'DELETE'}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteCampaign}
      />
    </div>
  )
}

const Row = ({ t, contact, onDelete, onEdit }) => {
  return (
    <Tr>
      <Td>{contact.email}</Td>
      <Td>
        {new Date(contact.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })}
      </Td>
      <Td className="capitalize">
        {contact.firstName} {contact.lastName}
      </Td>
      <Td>
        <div className="flex items-center">
          <div
            className={clsx(
              'w-2 h-2 mr-2 rounded-sm',
              contact.subscribed ? 'bg-green-500' : 'bg-red-500'
            )}
          ></div>
          <div className="capitalize">
            {contact.subscribed ? t('SUBSCRIBED') : t('UNSUBSCRIBED')}
          </div>
        </div>
      </Td>
      <Td center>
        <div
          className={clsx(
            contact.verified ? 'text-green-500' : 'text-red-500',
            'font-medium'
          )}
        >
          {contact.verified ? t('YES') : t('NO')}
        </div>
      </Td>
      <Td center className="whitespace-nowrap">
        <PopupMenu
          menuItems={[
            {
              label: t('EDIT'),
              method: onEdit,
              icon: <PenIcon />
            },
            {
              label: t('DELETE'),
              method: onDelete,
              icon: <DeleteIcon />,
              isDanger: true
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default CampaignContactsTable
