import axios from 'axios'
import { catchAsync } from 'utils'

/**
 * Fetch email template
 * @param {null|object} data
 * @param {Function} callback
 */
export const fetchEmailTemplates = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/email-template/user'
  })
  if (callback) callback(res.data)
})

/**
 * Create client email signup template
 * @param {Object} data.body slug, subject, body, variables
 * @param {Function} callback
 */
export const createClientSignupEmailTemplate = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'POST',
      url: '/email-template',
      data: data.body
    })
    if (callback) callback(res.data)
  }
)

/**
 * Update email template
 * @param {String} data.templateId
 * @param {Object} data.body slug, subject, body, variables
 * @param {Function} callback
 */
export const updateEmailTemplate = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/email-template/${data.templateId}`,
    data: data.body
  })
  if (callback) callback(res.data)
})

/**
 * Creates campaign email template
 * @param {Object} data.body html, subject, campaign
 * @param {Function} callback
 */
export const createCampaignEmailTemplate = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'POST',
      url: '/template',
      data: data.body
    })
    if (callback) callback(res.data)
  }
)

/**
 * Updates campaign email template
 * @param {Object} data.body html, subject, campaign
 * @param {Function} callback
 */
export const updateCampaignEmailTemplate = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'PUT',
      url: `/template/${data.templateId}`,
      data: data.body
    })
    if (callback) callback(res.data)
  }
)

/**
 * Fetches email templates by campaign id
 * @param {String} data.campaignId
 * @param {Function} callback
 */
export const fetchCampaignEmailTemplates = catchAsync(
  async (data, callback) => {
    const res = await axios({
      method: 'GET',
      url: `/template/campaign/${data.campaignId}`
    })
    if (callback) callback(res.data)
  }
)

/**
 * Deletes email template by id
 * @param {String} data.templateId
 * @param {Function} callback
 */
export const deleteEmailTemplateById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/template/${data.templateId}`
  })
  if (callback) callback(res.data)
})
