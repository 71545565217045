import React, { useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { createCampaign } from 'thunks/Campaign/actions'

const CreateCampaignModal = ({ open, onClose, addNewCampaign }) => {
  const { t } = useTranslation()

  return (
    <CustomModal open={open} handleClose={onClose}>
      <CustomModalHeader
        heading={`${t('CREATE')} ${t('SMART_CAMPAIGN')}`}
        handleClose={onClose}
      />
      <ModalContent t={t} onClose={onClose} addNewCampaign={addNewCampaign} />
    </CustomModal>
  )
}

const ModalContent = ({ t, onClose, addNewCampaign }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleCreateCampaign = () => {
    setLoading(true)
    createCampaign({ body: { campaignName: name } }, (res, err) => {
      setLoading(false)
      if (err) dispatch(fireErrorToaster(res))
      else {
        addNewCampaign(res)
        dispatch(fireSuccessToaster(t('SUCCESS')))
        onClose()
      }
    })
  }

  return (
    <>
      <CustomModalBody>
        <div className="mb-4">
          <FormLabel required>
            {t('CAMPAIGN')} {t('NAME')}
          </FormLabel>
          <TextField
            fullWidth
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button size="large" variant="outlined" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton
          size="large"
          onClick={handleCreateCampaign}
          loading={loading}
        >
          {t('CREATE')}
        </LoadingButton>
      </CustomModalFooter>
    </>
  )
}

export default CreateCampaignModal
