import React, { useMemo, useState } from 'react'
import { Pagination, PopupMenu } from 'global/globalComponents'
import {
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as PenIcon } from 'static/svg/pen.svg'
import { deleteCampaign } from 'thunks/Campaign/actions'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { useModal } from 'customHooks'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useHistory, useRouteMatch } from 'react-router-dom'

const limit = 8

const CampaignsTable = ({ isLoading, campaignsData, removeCampaign }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const search = useSelector(state => state.generalAppState.headerSearch)
  const [page, setPage] = useState(0)
  const {
    modal: deleteAlert,
    openModal: openDeleteAlert,
    closeModal: closeDeleteAlert,
    toggleLoading
  } = useModal()
  const history = useHistory()
  const { url } = useRouteMatch()

  const visibleCampaigns = useMemo(() => {
    let arr = [...campaignsData]

    if (search.trim() !== '') {
      arr = arr.filter(item =>
        item.campaignName.toLowerCase().includes(search.toLowerCase())
      )
    }

    return arr.splice(page * limit, limit)
  }, [page, search, campaignsData])

  const handleDeleteCampaign = () => {
    toggleLoading()
    deleteCampaign({ campaignId: deleteAlert.data._id }, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        toggleLoading()
      } else {
        removeCampaign(deleteAlert.data._id)
        dispatch(fireSuccessToaster(t('SUCCESS')))
        closeDeleteAlert()
      }
    })
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">
            {t('CAMPAIGN')} {t('NAME')}
          </Th>
          <Th className="whitespace-nowrap" center>
            {t('STATUS')}
          </Th>
          <Th className="w-20"></Th>
        </Thead>
        <Tbody>
          {isLoading ? (
            <TableDataLoading cols={3} />
          ) : visibleCampaigns.length > 0 ? (
            visibleCampaigns.map((item, index) => (
              <Row
                t={t}
                key={index}
                campaignData={item}
                onDelete={() => openDeleteAlert(item)}
                onClick={() => history.push(`${url}/${item._id}`)}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                {t('NO_DATA_AVAILABLE')}
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <Pagination
        handlePagination={setPage}
        totalResultsCount={campaignsData.length}
        page={page}
        limit={limit}
      />

      <AlertModal
        open={deleteAlert.open}
        loading={deleteAlert.loading}
        warningText={t('WARNING_DELETE', {
          data: deleteAlert.data?.campaignName
        })}
        deleteBtnText={'DELETE'}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteCampaign}
      />
    </div>
  )
}

const Row = ({ t, campaignData, onDelete, onClick }) => {
  return (
    <Tr>
      <Td onClick={onClick} className="cursor-pointer">
        {campaignData.campaignName}
      </Td>
      <Td center className="capitalize">
        {campaignData.status}
      </Td>
      <Td center className="whitespace-nowrap">
        <PopupMenu
          menuItems={[
            {
              label: t('EDIT'),
              method: onClick,
              icon: <PenIcon />
            },
            {
              label: t('DELETE'),
              method: onDelete,
              icon: <DeleteIcon />,
              isDanger: true
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default CampaignsTable
